import React from "react";
import Token from "./features/Token";
import { ProfileData } from '/src/data';

function TokensWidget() {
    const { data: tokens, loading: tokensLoading, error: tokensError } = ProfileData.useUserTokens();

    return (
        <div className="card-wrapper mb-4">
            <div className="row">
                <div className="col">
                    <h5 className="card-label">Tracked Tokens</h5>
                </div>
            </div>

            {(tokensLoading) && (
                <div>A moment please...</div>
            )}

            {(tokensError) && (
                <div>{`There is a problem fetching the data - ${tokensError}`}</div>
            )}

            {tokens && tokens.length > 0 ?
                <>
                    {tokens.map(
                        (token, index) => (
                            <Token key={index} token={token}></Token>
                        )
                    )}
                </>
                :
                <div className="card">
                    <div className="card-body">You are not tracking any tokens. Click here to start tracking tokens.</div>
                </div>
            }
        </div>
    );

}

export default TokensWidget;
