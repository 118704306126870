import React from 'react';
import { useOutletContext } from "react-router-dom";
import { Navigation } from '../../components';

function Settings() {
    const [account] = useOutletContext();

    return (
        <div className="main-content">
            <div className="container-lg">
                <div className="page-header">
                    <h1>Settings</h1>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        Company Account Settings go here
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Settings;
