
import React, { useEffect, useState, useRef } from "react";
import { Modal as BSModal } from "bootstrap";
import ModalComponent from "./ModalComponent";
import ModalContext from "./ModalContext";

const ModalProvider = ({ children }) => {
    const modalRef = useRef(null);
    const [_modal, setModal] = useState();
    const [shown, setShown] = useState(false);
    const [modalProps, setModalProps] = useState(null);
    const [handlers, setHandlers] = useState(null);
    const [disable, setDisable] = useState(false);

    /**
     * This is the function attached to the context, invoking this function
     * with props and handlers for the modal will trigger the modal showing
     */
    function modal(content, props = {}, { ...options }) {
        const { title, close, confirm, confirmText, header, footer } = options
        setHandlers({ close, confirm })
        setModalProps({
            confirmText,
            content: {
                type: content,
                props
            },
            footer,
            header,
            title,
        })
    }

    function onCloseModal() {
        _modal.hide();
        setShown(false);
        setDisable(false);
    }

    function onConfirmModal() {
        if (handlers?.confirm) {
            setDisable(true)
            handlers.confirm()
                .then((result) => {
                    if (result == null) {
                        return onCloseModal();
                    }

                    // when falsey, don"t close the modal.
                    if (!result) {
                        setDisable(false);
                        return;
                    }

                    if (typeof result === "object") {
                        if (result.success) {
                            return onCloseModal();
                        }

                        if (result.props != null) {
                            // shallow copy in the originals, then override them with any new ones.
                            const newProps = { ...modalProps };
                            newProps.content.props = { ...newProps.content.props, ...result.props }
                            setModalProps(newProps);
                        }
                        setDisable(false);
                        return;
                    }

                    onCloseModal();
                })
        } else {
            // close anyway; no confirm handler
            onCloseModal();
        }
    }

    useEffect(() => {
        const _modal = new BSModal(modalRef.current, { backdrop: "static" });
        setModal(_modal);
    }, [modalRef]);

    useEffect(() => {
        if (!modalProps || !handlers) {
            return;
        }
        _modal.show();
        setShown(true);
    }, [modalProps, handlers])

    useEffect(() => {
        if (!shown) {
            // NOTE: closed, call close handler if avail.
            if (handlers?.close) {
                handlers.close() // may be async, we'll push through this one. Not expecting a result
            }

            // NOTE: clear state
            setModalProps(null)
            setHandlers(null)
        }
    }, [shown])

    return (
        <ModalContext.Provider value={{ modal }}>
            {children}
            <ModalComponent
                disabled={disable}
                close={onCloseModal}
                confirm={onConfirmModal}
                ref={modalRef}
                {...modalProps}
            />
        </ModalContext.Provider>
    )
}

export default ModalProvider;
