import React from 'react';
import cn from "classnames";

const ChapterItem = ({ chapter, index, current = false, completed = false }) => {
    const chapterCardClassNames = cn({
        'item-chapter': true,
        'chapter-completed': completed,
        'chapter-current': current,
    });

    return (
        <div className={chapterCardClassNames}>
            <div className="row gx-2">
                <div className="col-auto">
                    {completed ? <div className="item-chapter-number glyph-check"></div> : <div className="item-chapter-number">{index + 1}</div>}
                </div>
                <div className="col-6">
                    <span>{chapter.name}</span>
                </div>
                <div className="col-auto">
                    <span className="duration">
                        <i className="glyph-timer"></i> {chapter.duration}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ChapterItem;
