import config from 'config';
import { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query'
import { authHeader } from '../helpers';

function useReadChains() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const url = `${config.apiDomain}/api/chains?`;

    const [chains, setChains] = useState([]);

    const { isPending, isError, data, error } = useQuery({ 
        queryKey: ['chains'], 
        staleTime: 5 * 60 * 1000, // (5 minutes) 
        queryFn: async () => {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            return await response.json();
        }
    });

    useEffect(() => {
        if (data) {
            setChains(data.payload);
        }
    }, [data]);

    return { chains, isPending, isError };
}

function getChainLabel (chains, chainId) {
    if (chains?.length && chainId) {
        const chain = chains.find(item => item.chainId == chainId);
        return chain.name;
    } 
}

export default {
    useReadChains,
    getChainLabel,
};
