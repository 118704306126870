import config from "config";
import { authHeader, handleResponse } from "../helpers";

function create(accountId, reportId, data) {
    if (!data.file.item(0)) {
        return Promise.reject(new Error("No file"));
    }

    const formData = new FormData();
    formData.append("file", data.file.item(0));

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/reports/${reportId}/files`, requestOptions)
        .then(response => handleResponse(response));
}

export default {
    create,
};
