import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext } from "react-router-dom";
import __ from '@foragefox/doubledash';
import cn from "classnames";
import { CoursesData, ProfileData } from '/src/data';
import { Filters, PageHeader } from '/src/components';
import CoursePreview from './features/CoursePreview';
import { SORTS } from "./constants";

const CourseItem = ({ course, userCourses, open, animate, handleOpen, handleClose }) => {
    const { id, category, name, status } = course;
    const itemRef = useRef(null);
    const userCourse = userCourses?.find((enrolment) => enrolment.courseId === id);
    const userProgress = userCourse?.progress ?? 0;

    animate = animate && animate.id == id ? animate : null;

    const itemClassNames = cn({
        'courses-item': true,
        'courses-item-expanded': open,
        'courses-item-expanded-animate': animate,
        [`${category.className}`]: true,
        'courses-item-draft': status == 0
    });

    const styles = {
        height: animate ? animate.height : null
    };

    const handleTransitionEnd = (event) => {
        event.persist();

        if (event.propertyName == 'opacity') {
            const offset = __.location.offset(itemRef.current);
            window.scrollTo(0, offset.top);
        }
    };

    const handleCardClick = (event) => {
        if (status == 1) {
            handleOpen(event);
        }
    }

    return (
        <div
            data-id={id}
            className={itemClassNames}
            style={styles}
            onTransitionEnd={handleTransitionEnd}
            ref={itemRef}
        >
            <div className="card card-course" onClick={handleCardClick}>

                <div className="card-body">
                    <div className="card-category">{category.name}</div>
                    <h4 className="card-title">{name}</h4>
                </div>

                <div className="card-footer">
                    <div className="row justify-content-between">
                        <div className="col-7 align-self-end">
                            {status == 0 &&
                                <>Coming soon...</>
                            }
                            {userProgress > 0 &&
                                <div className="progress"
                                    role="progressbar"
                                    aria-label="Course Progress"
                                    aria-valuenow={userProgress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    <div className="progress-bar" style={{ width: userProgress + '%' }}></div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            <img className="card-icon" src={category.icon} />
                        </div>
                    </div>
                </div>
            </div>

            {open ?
                <CoursePreview
                    handleClose={handleClose}
                    course={course}
                    userCourses={userCourses}
                />
                : ''}

        </div>
    )
}

const CoursesGrid = ({ sort, filter }) => {
    const [selected, setSelected] = useState(null);
    const [animate, setAnimate] = useState(null);
    const [account] = useOutletContext();
    const { data: courses, loading, error } = CoursesData.useReadCourses(account.id, sort, filter);
    const {
        data: userCourses,
        loading: userCoursesLoading,
        error: userCoursesError
    } = ProfileData.useUserCourses();

    useEffect(() => {
        const itemElement = __.dom.findOne(`.courses-item-expanded[data-id="${selected}"]`);

        if (itemElement) {
            const previewElement = __.dom.findOne('.courses-item-preview', itemElement);
            if (previewElement) {
                const height = __.size.height(itemElement) + __.size.outerHeight(previewElement, true);
                setAnimate({ id: selected, height: height });
            }
        }
    }, [selected])
    function handleOpen(event) {
        const element = event.currentTarget.parentNode;

        if (element.dataset.id === selected) {
            setSelected(null);
            setAnimate(null);
        } else {
            setSelected(element.dataset.id);
        }
    }

    function handleClose(id) {
        setSelected(null);
        setAnimate(null);
    }

    return (
        <div className="courses">
            <div className="row g-4">
                {loading && (
                    <div>A moment please...</div>
                )}

                {error && (
                    <div>{`There is a problem fetching the data - ${error}`}</div>
                )}

                {courses && courses.map(
                    (course, index) => (
                        <div key={index} className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3">
                            <CourseItem
                                key={index}
                                course={course}
                                userCourses={userCourses}
                                open={selected == course.id}
                                animate={animate}
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                            ></CourseItem>
                        </div>
                    )
                )
                }
            </div>
        </div>
    );
}

const Courses = () => {
    const [account] = useOutletContext();

    const [sort, setSort] = useState(SORTS[0]?.value);
    const [filter, setFilter] = useState(null);

    const { data: categories } = CoursesData.useReadCourseCategories(account.id);

    return (
        <div className="main-content">

            <div className="container-fluid">

                <PageHeader title="Courses"></PageHeader>

                <Filters sorts={SORTS} filters={categories} onSort={sort => setSort(sort)} onFilter={filter => setFilter(filter)} />

                <CoursesGrid sort={sort} filter={filter} />

            </div>

        </div>
    )
}

export default Courses;