import React from "react";
import { Link, useOutletContext } from "react-router-dom";

function CourseComplete({ restartCourse }) {
    const [account] = useOutletContext();

    return (
        <div className="d-flex flex-column align-items-center pt-5 pb-5">
            <h2 className="mb-5">You have completed this course!</h2>
            <div>
                <button className="btn btn-secondary me-3" onClick={restartCourse}>
                    Restart course
                    <span className="glyph-rotate-left ms-1"></span>
                </button>
                <Link className="btn btn-dark" to={`/${account.id}/courses`}>
                    View more courses
                    <span className="glyph-chevron-right ms-1"></span>
                </Link>
            </div>
        </div>
    );
}

export default CourseComplete;
