import React from "react";
import { ProfileData } from '/src/data';
import { NavLink } from "react-router-dom";

function BalanceWidget({ account, wallets }) {
    const hasElevatedAccount = account.plan != 'free';
    const { data: balances, loading, error } = ProfileData.useUserBalances();
    const latestBalance = (hasElevatedAccount && balances && balances.length > 0) ? balances.at(-1).amount : 0;
    const title = hasElevatedAccount ? '' : 'Not available with free account.';

    return (
        <div className="card card-balance mb-5 text-bg-dark" title={title}>
            {loading && (
                <div className="card-body">A moment please...</div>
            )}

            {error && (
                <div className="card-body">{`There is a problem fetching the data - ${error}`}</div>
            )}

            {balances && balances.length > 0 ?
                <>
                    <div className="card-body">
                        <h6>My Earnings</h6>
                        <h3>${(latestBalance).toLocaleString('en-US', { minimumFractionDigits: 2 })}</h3>
                    </div>
                </> :
                <div className="card-body">
                    {wallets && wallets.length > 0 ?
                        <p>
                            Calculating. Check back later.
                        </p>
                        :
                        <>
                            <p>
                                Connect a wallet to start tracking your portfolio growth?
                            </p>
                            <NavLink className="btn btn-outline-light" to="/profile/wallets">CONNECT WALLETS</NavLink>
                        </>
                    }
                </div>
            }
        </div>
    );
}

export default BalanceWidget;
