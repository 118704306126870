import { useState, useEffect } from "react";
import config from "config";
import { authHeader, getParams, handleResponse } from "../helpers";

function useReadTokens(name = null, symbol = null, address = null, chainId = null) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const url = `${config.apiDomain}/api/tokens?`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url + getParams({ name, symbol, address, chainId }), requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [name, symbol, address, chainId]);

    return { data, error, loading };
}

export default {
    useReadTokens,
}
