import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import cn from 'classnames';
import AuthService from '/src/services/AuthService';
import { getProfilePhoto } from '/src/helpers';
import { ProfileData } from '/src/data';
import { PageHeader } from '/src/components';

function Photo() {
    const [profile, setProfile] = useState();
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { data, loading, error } = ProfileData.useUserProfile();

    useEffect(() => {
        setProfile(data);
    }, [data]);

    const registerOptions = {
        photo: {
            required: "Please choose file",
            validate: {
                lessThan4MB: files => files[0]?.size < 4_000_000 || 'Max 4MB',
                acceptedFormats: files =>
                    ['image/jpeg', 'image/png'].includes(files[0]?.type)
                    || 'Only PNG, JPEG images',
            }
        }
    };

    const onPhotoSubmit = (data) => {
        ProfileData.updatePhoto(data)
            .then((response) => {
                setProfile({ photo: response.photo });

                AuthService.authUser.photo = response.photo;
                AuthService.update(AuthService.authUser);

                reset()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const onPhotoRemove = (data) => {
        ProfileData.removePhoto(data)
            .then((response) => {
                setProfile({ photo: '' });

                AuthService.authUser.photo = '';
                AuthService.update(AuthService.authUser);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    return (
        <div className="main-content">
            <div className="container-lg">

                <PageHeader title="Photo"></PageHeader>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-body">

                                {loading && (
                                    <div className="row">
                                        <div className="col-lg-6">A moment please...</div>
                                    </div>
                                )}

                                {error && (
                                    <div>There is a problem fetching the data</div>
                                )}

                                {profile && (
                                    <form onSubmit={handleSubmit(onPhotoSubmit, onError)} className="needs-validation">
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="img-fluid" src={getProfilePhoto(profile)} />
                                                {profile.photo && (
                                                    <p className="help-block text-center">
                                                        <button
                                                            className="btn btn-sm btn-text"
                                                            onClick={onPhotoRemove}
                                                            type="button"
                                                        >Remove Your Photo</button>
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-8">
                                                <div className="mb-3">
                                                    <label htmlFor="photo" className="form-label">Select an image file (4MB max)</label>
                                                    <input
                                                        className={
                                                            cn({ 'form-control': true, 'is-invalid': errors.photo })
                                                        }
                                                        type="file"
                                                        id="photo"
                                                        accept="image/png, image/jpeg"
                                                        {...register('photo', registerOptions.photo)} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="photo"
                                                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                                    />
                                                </div>
                                                <div className="">
                                                    <button type="submit" className="btn btn-primary">SAVE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Photo;
