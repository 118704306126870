import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReportData } from "/src/data";
import ReportForm from "./features/ReportForm";

function CreateReport() {
    const [account] = useOutletContext();
    const navigate = useNavigate();

    const onSubmit = (data, event) => {
        ReportData.create(account.id, data)
            .then((response) => {
                navigate(`/${account.id}/reports/${response.reportId}`);
            })
            .catch((err) => { })
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    return (
        <div className="main-content">
            <div className="container">
                <div className="page-header condensed">
                    <h1>Create Report</h1>
                </div>
                <ReportForm onSubmit={onSubmit} onError={onError} />
            </div>
        </div>
    )
}

export default CreateReport;
