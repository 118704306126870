import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import AuthService from '../services/AuthService';
import NotFound from "../pages/NotFound";
import { AccountData } from '../data';
import { Navigation } from '../components';

const AccountLayout = () => {
    const { accountId } = useParams();
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const authUser = AuthService.authUser;
    if (!hasAccount(authUser, accountId)) {
        return <NotFound />
    }

    useEffect(() => {
        AccountData.read(accountId)
            .then((payload) => {
                setAccount(payload);
                setError(null);
            })
            .catch((err) => {
                setAccount(null);
                setError('No account found');
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            {loading && (
                <div>A moment please...</div>
            )}

            {error && (
                <div>{`There is a problem fetching the data - ${error}`}</div>
            )}

            {account && (
                <>
                    <Navigation account={account} />
                    <Outlet context={[account]} />
                </>
            )}
        </>
    );
}

function hasAccount(user, accountId) {
    if (user.accounts && user.accounts.length > 0) {
        for (let i = 0; i < user.accounts.length; i++) {
            if (user.accounts[i].id == accountId) {
                return true;
            }
        }
    }

    return false;
}

export default AccountLayout;
