import config from 'config';
import { authHeader, getParams, handleResponse } from '../helpers';
import { useState, useEffect } from "react";

function useReadCourses(accountId, sort = null, filter = null) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };
    const url = `${config.apiDomain}/api/courses?`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url + getParams({ sort: sort, filter: filter }), requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId, sort, filter]);

    return { data, error, loading };
}

function useReadCourse(accountId, courseId) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };
    const url = `${config.apiDomain}/api/courses/${courseId}`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (![...arguments].every(Boolean)) {
            return;
        }
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [...arguments]);

    return { data, error, loading };
}

function useReadChapter(accountId, courseId, chapterId) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };
    const url = `${config.apiDomain}/api/courses/${courseId}/chapters/${chapterId}`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (![...arguments].every(Boolean)) {
            return;
        }
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [...arguments]);

    return { data, error, loading };
}

function useReadCourseCategories(accountId) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`${config.apiDomain}/api/courses/categories`, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId]);

    return { data, error, loading };
}

export default {
    useReadCourses,
    useReadCourse,
    useReadChapter,
    useReadCourseCategories
};