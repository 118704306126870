import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import AuthService from '../services/AuthService';

const ProtectedLayout = () => {
    const location = useLocation();

    const authUser = AuthService.authUser;
    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" replace={true} state={{ from: location }} />
    }

    return <Outlet />;
}

export default ProtectedLayout;
