import React, { useState, useEffect } from 'react';

function PageHeader({title, badge, date}) {
    const [isLeftNav, setLeftNav] = useState(false);
   
    useEffect(() => {
        document.body.classList.toggle('navigation-open', isLeftNav);
    });

    const handleLeftNavToggle = () => {
        setLeftNav(!isLeftNav);
    };

    return (
        <div className="page-header">
            <div className="row">
                <div className="col-9">
                    <a className="navigation-toggle" onClick={() => handleLeftNavToggle()}><i className="glyph-menu"></i></a>

                    <h2>{title} {badge && <span className="badge bg-secondary">{badge}</span>}</h2>
                    
                    {date && <div className="date">{date}</div>}
                </div>
            </div>
        </div>
    );

}

export default PageHeader;
