import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import { AccountData } from '../data';
import AuthService from '../services/AuthService';

function Accounts() {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    const authUser = AuthService.authUser;
    if (!authUser.accounts || authUser.accounts.length == 0) {
        return <Navigate to={'/register/wallet'} />
    }

    if (authUser.accounts.length == 1) {
        return <Navigate to={'/' + authUser.accounts[0].id} />
    }

    useEffect(() => {
        AccountData.read()
            .then((payload) => {
                setAccounts(payload);
            })
            .catch((err) => {
                setAccounts([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="offset-md-3 col-md-6 offset-lg-4 col-lg-4">

                    {loading && (
                        <div className="accounts">A moment please...</div>
                    )}

                    {accounts && accounts.length > 0 && (
                        <div className="account-page">
                            <h3 className="text-center">Choose an account</h3>

                            <div className="account-list">
                                {accounts.map(
                                    ({ id, name }, index) => (
                                        <Link key={index} to={'/' + id}>
                                            <img className="photo" height="40" width="40" src="https://demo-2-disk.nyc3.digitaloceanspaces.com/img/company.png" />
                                            <span className="name">{name}</span>
                                            <span className="glyph-chevron-right"></span>
                                        </Link>
                                    )
                                )}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

export default Accounts;
