import config from 'config';
import { authHeader, handleResponse } from '../helpers';

function create(data) {
    const headers = authHeader();
    headers["Accept"] = "application/json";

    const formData = new FormData();
    formData.append('plan', data.plan);

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: formData
    };

    return fetch(`${config.apiDomain}/api/accounts`, requestOptions)
        .then(response => handleResponse(response, true));
}

function read(id) {

    const requestOptions = { method: 'GET', headers: authHeader() };

    if (id) {
        return fetch(`${config.apiDomain}/api/accounts/read?${new URLSearchParams({ id: id }).toString()}`, requestOptions)
            .then(response => handleResponse(response, true));
    } else {
        return fetch(`${config.apiDomain}/api/accounts`, requestOptions)
            .then(response => handleResponse(response, true));
    }
}

function update(accountId, data) {
    const headers = authHeader();
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/accounts/${accountId}`, requestOptions)
        .then(response => handleResponse(response, true));
}

export default {
    create,
    read,
    update
};