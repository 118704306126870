import React from 'react';
import { Link, useOutletContext } from "react-router-dom";
import ChapterItem from './ChapterItem';

const CoursePreview = ({ course, handleClose, userCourses }) => {
    const { chapters, duration, description, id, name, prerequisites, thumbnail } = course;
    const userCourse = userCourses?.find((enrollment) => enrollment.courseId === id);
    const userProgress = userCourse?.progress ?? 0;
    const userCompleted = userCourse?.completed ?? 0;
    const [account] = useOutletContext();

    const completedCourses = userCourses
        .filter((userCourse) => userCourse.completed === 1)
        .map((userCourse) => userCourse.courseId);

    return (
        <div className="courses-item-preview">

            <span className="glyph glyph-close" onClick={() => handleClose(id)}></span>

            <div className="row">
                <div className="col-md-auto">
                    <div style={{ minHeight: '250px', minWidth: '250px' }}>
                        <img className="img-fluid" src={thumbnail} />
                    </div>
                    <div className="progress"
                        role="progressbar"
                        aria-label="Course Progress"
                        aria-valuenow={userProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <div className="progress-bar" style={{ width: userProgress + '%' }}></div>
                    </div>
                </div>
                <div className="col-md">
                    <h3>
                        {name}

                        {userCompleted != 0 && <span className='badge badge-completed'>COMPLETED</span>}
                    </h3>

                    <p className='duration'>
                        <i className="glyph-timer"></i> {duration}
                    </p>

                    <p>{description}</p>

                       
                    {prerequisites.length > 0 &&
                        <section>
                            <h5>Prerequisites</h5>
                            <div className={prerequisites.length > 3 ? 'split-list' : ''}>
                                {prerequisites.map(
                                    (prerequisite, index) => {
                                        const className = completedCourses.includes(prerequisite.id)
                                            ? 'glyph-check-circle'
                                            : 'glyph-minus-circle';
                                        return (
                                            <div key={index}><i className={className}></i> {prerequisite.name}</div>
                                        );
                                    }
                                )}
                            </div>
                        </section>
                    }
                    
                    {chapters.length > 0 &&
                        <section>
                            <h5>Chapters</h5>
                            <div className={chapters.length > 5 ? 'split-list' : ''}>
                                {chapters.map((chapter, index) =>
                                    <ChapterItem
                                        key={index}
                                        index={index}
                                        chapter={chapter}
                                        current={userCourse?.chapter === (index + 1)}
                                        completed={userCourse?.chapter > (index + 1) || userCompleted == 1} />
                                )}
                            </div>
                        </section>
                    }

                    <div className="row justify-content-end">
                        <div className="col-6 text-end">
                            <Link className="btn btn-dark" to={`/${account.id}/courses/${id}`}>
                                {userCourse ? "Continue" : "Start"}
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="col-1"></div>
            </div>
        </div>
    );
}

export default CoursePreview;
