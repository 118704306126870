import { useState, useEffect } from 'react';
import config from 'config';
import { authHeader, handleResponse } from '../helpers';

function read(accountId) {

    const requestOptions = { method: 'GET', headers: authHeader(accountId) };

    return fetch(`${config.apiDomain}/api/users`, requestOptions)
        .then(response => handleResponse(response, true));
}

function useReadUsers(accountId) {
    const requestOptions = { method: 'GET', headers: authHeader(accountId) };

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`${config.apiDomain}/api/users`, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId]);

    return { data, error, loading };
}

function remove(accountId, id) {

    let formData = new FormData();
    formData.append('id', id);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/users/remove`, requestOptions)
        .then(response => handleResponse(response));

}

function makeAdmin(accountId, id) {

    let formData = new FormData();
    formData.append('id', id);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/users/make-admin`, requestOptions)
        .then(response => handleResponse(response));

}

function removeAdmin(accountId, id) {

    let formData = new FormData();
    formData.append('id', id);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/users/remove-admin`, requestOptions)
        .then(response => handleResponse(response));

}

export default {
    read,
    remove,
    makeAdmin,
    removeAdmin,
    useReadUsers
};