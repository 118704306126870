import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import cn from "classnames";
import { ProfileData } from '/src/data';
import { NavLink } from "react-router-dom";

const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                display: false,

            },
            border: {
                display: false
            }
        },
        y: {
            display: false,
        },
    },
};

const placeholderData = [
    { amount: 1000, date: "2023-01-31" },
    { amount: 1200, date: "2023-02-28" },
    { amount: 1800, date: "2023-03-31" },
    { amount: 1700, date: "2023-04-30" },
    { amount: 2200, date: "2023-05-31" },
    { amount: 2300, date: "2023-06-30" }
];
const placeholderColor = 'rgb(150, 150, 150)';

function getMonth(date) {
    return new Date(date.split('-')).toLocaleString('default', { month: 'short' })
}

function GrowthWidget({ account, wallets }) {
    const hasElevatedAccount = account.plan != 'free';
    const { data: balances, loading, error } = ProfileData.useUserBalances();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(hasElevatedAccount ? balances : placeholderData);
    }, [hasElevatedAccount, balances]);

    const cardClassNames = cn({
        'card': true,
        'card-growth': true,
        'mb-4': true,
        'opacity-50': !hasElevatedAccount
    });

    const title = hasElevatedAccount ? '' : 'Not available with free account.';

    return (
        <div className={cardClassNames} title={title}>
            {loading && (
                <div className="card-body">A moment please...</div>
            )}

            {error && (
                <div className="card-body">{`There is a problem fetching the data - ${error}`}</div>
            )}

            {chartData && chartData.length > 0 ?
                <>
                    <div className="card-body">
                        <h5 className="card-label">Growth</h5>
                    </div>
                    <div className="card-footer">
                        <Line
                            options={options}
                            data={{
                                labels: chartData.map(a => getMonth(a.date)),
                                datasets: [
                                    {
                                        data: chartData.map(a => a.amount),
                                        borderColor: hasElevatedAccount ? 'rgb(255, 99, 132)' : placeholderColor,
                                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                        tension: 0.4
                                    }
                                ],
                            }}
                        />
                    </div>
                </>
                :
                <div className="card-body">
                    {wallets && wallets.length > 0 ?
                        <p>
                            Calculating. Check back later.
                        </p>
                        :
                        <>
                            <p>
                                Connect a wallet to start tracking your portfolio growth.
                            </p>
                            <NavLink className="btn btn-outline-light" to="/profile/wallets">CONNECT WALLETS</NavLink>
                        </>
                    }
                </div>
            }
        </div>
    );
}

export default GrowthWidget;
