import React from 'react';
import cn from "classnames";

function Filters({
    className,
    sorts,
    onSort,
    filters,
    onFilter,
    ...props
}) {

    const classNames = cn({
        'filters': true
    });

    return (
        <div className={classNames}>
            <div className="row row-cols-auto">

                {sorts &&
                    <div className="col">
                        <div className="row row-cols-auto g-0 align-items-center">
                            <div className="col">
                                <label className="col-form-label" htmlFor="sort-by">Sort by</label>
                            </div>
                            <div className="col">
                                <select
                                    className="form-select"
                                    id="sort-by"
                                    defaultValue=""
                                    onChange={(e) => onSort(e.target.value)}
                                >
                                    {sorts.map(
                                        (sort, index) => (
                                            <option key={index} value={sort.value}>{sort.label}</option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                }

                {filters &&
                    <div className="col">
                        <div className="row row-cols-auto g-0 align-items-center">
                            <div className="col">
                                <label className="col-form-label" htmlFor="type">Type</label>
                            </div>
                            <div className="col">
                                <select
                                    className="form-select"
                                    id="type"
                                    defaultValue=""
                                    onChange={(e) => onFilter(e.target.value)}
                                >
                                    <option value="">None</option>
                                    {filters.map(
                                        (filter, index) => (
                                            <option key={index} value={filter.id}>{filter.name}</option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Filters;
