import { useState, useEffect } from "react";
import config from "config";
import { authHeader, getParams, handleResponse } from "../helpers";

function useReadReports(accountId, sort = null, filter = null, limit = null) {
    const requestOptions = { method: 'GET', headers: authHeader(accountId) };
    const url = `${config.apiDomain}/api/reports?`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url + getParams({ sort: sort, filter: filter, limit: limit }), requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId, sort, filter]);

    return { data, error, loading };
}

function useReadReport(accountId, reportId) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };
    const url = `${config.apiDomain}/api/reports`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url + `/${reportId}`, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId, reportId]);

    return { data, error, loading };
}

function useReadReportCategories(accountId) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`${config.apiDomain}/api/reports/categories`, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId]);

    return { data, error, loading };
}

function useReadReportScore(accountId, reportId, counter = 1) {
    const requestOptions = { method: "GET", headers: authHeader(accountId) };
    const url = `${config.apiDomain}/api/reports`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (counter == 0)
            return;
        setLoading(true);
        fetch(url + `/${reportId}/score`, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [accountId, reportId, counter]);

    return { data, error, loading };
}

function createScore(accountId, reportId, data) {
    const headers = authHeader(accountId);
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/reports/${reportId}/score`, requestOptions)
        .then(response => handleResponse(response));
}

export default {
    useReadReports,
    useReadReport,
    useReadReportCategories,
    useReadReportScore,
    createScore,
};