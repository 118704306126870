import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { getProfilePhoto } from '../helpers';
import AuthService from '../services/AuthService';
import classNames from "classnames";

function Navigation({ account, user, ...props }) {
    const navigate = useNavigate();
    const [isProfileOpen, setProfileOpen] = useState(false);
    const [isAccountsOpen, setAccountsOpen] = useState(false);

    const authUser = AuthService.authUser;

    const handleProfileToggle = () => {
        setProfileOpen(!isProfileOpen);
    };

    const handleAccountsToggle = () => {
        setAccountsOpen(!isAccountsOpen);
    };
    
    const handleLogout = () => {
        AuthService.logout();
        navigate('/login');
    }

    return (
        <>
        <div className="navigation">
            <div>
                <NavLink className="navbar-brand" to={`/${account.id}`}>
                    <img src="/img/thonic_logo_blue.svg" />
                    <span className="title">THONIC</span>
                </NavLink>
           
                {account &&
                    <ul className="left-side-nav">
                        <li className="side-nav-item">
                            <NavLink to={`/${account.id}`} end className="side-nav-link">
                                <i className="glyph-bar-chart"></i> <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="side-nav-item">
                            <NavLink to={`/${account.id}/reports`} className="side-nav-link">
                                <i className="glyph-file-copy"></i> <span>Reports</span>
                            </NavLink>
                        </li>
                        <li className="side-nav-item">
                            <NavLink to={`/${account.id}/courses`} className="side-nav-link">
                                <i className="glyph-menu-book"></i> <span>Courses</span>
                            </NavLink>
                        </li>
                    </ul>
                }
            </div>

            <a className="profile-link" onClick={handleProfileToggle}>
                <img className="photo" src={getProfilePhoto(authUser)} />
                {authUser.name}
                <i className="glyph-chevron-right"></i>
            </a>
            
            <div className={classNames({ 'user-session': true, 'open': isProfileOpen })}>
                <div className="profile">
                    <img className="photo" src={getProfilePhoto(authUser)} />
                    <div className="details">
                        <div><strong>{authUser.name}</strong></div>
                        <div>{authUser.email}</div>
                    </div>
                </div>

                <div className="options">
                    <Link to="/profile">Settings</Link>

                    {authUser.accounts && authUser.accounts.length > 1 && (
                        <>
                            <a onClick={handleAccountsToggle}>
                                Accounts  
                                <span className="glyph glyph-chevron-right"></span>
                            </a>
                            <div className={classNames({ 'accounts': true, 'open': isAccountsOpen })}>
                                {authUser.accounts.map(
                                    ({ id, name }, index) => (
                                        <a key={index} href={'/' + id}>
                                            <span className="name">{name}</span>
                                           
                                        </a>
                                    )
                                )}
                            </div>
                        </>
                    )}

                
                    <a onClick={handleLogout}>Log out</a>
                </div>

            </div>

        </div>

        
</>
    );
}

export default Navigation;
