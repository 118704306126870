import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { DropdownLink, Confirm } from '../../components';
import { UserData, InviteData } from '../../data';
import { getProfilePhoto } from '../../helpers';

function Users() {

    const [account] = useOutletContext();
    const [users, setUsers] = useState(null);
    const [invites, setInvites] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {

        Promise.all([
            UserData.read(account.id),
            InviteData.read(account.id),
        ]).then((values) => {
            setUsers(values[0]);
            setInvites(values[1]);

            setError(null);
        }).catch((err) => {
            setUsers(null);
            setInvites(null);

            setError('No users found');
        }).finally(() => {
            setLoading(false);
        });
       
    }, []);

    const onResend = (id) => {
        InviteData.resend(account.id, id);
    };

    const onRevoke = (id, index) => {
        InviteData.revoke(account.id, id)
            .then((response) => {
                setInvites(invites.filter((v, i) => i !== index));
            });
    };

    const onMakeAdmin = (id) => {
        UserData.makeAdmin(account.id, id);
    };

    const onRemoveAdmin = (id) => {
        UserData.removeAdmin(account.id, id);
    };

    const onRemove = (id, index) => {
        UserData.remove(account.id, id)
            .then((response) => {
                setUsers(users.filter((v, i) => i !== index));
            });
    };

    return (
        <div className="main-content">

            <div className="container">
                
                <div className="page-header condensed">
                    <div className="row">
                        <div className="col-9">
                            <h1>Users</h1>
                        </div>
                        <div className="col-3 text-end">
                            <a href={'/' + account.id + '/users/invite'} title="Invite user" className="btn btn-primary btn-lg btn-round"><span className="glyph glyph-plus"></span></a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        {loading && (
                            <div>A moment please...</div>
                        )}
                        
                        {error && (
                            <div>{`There is a problem fetching the data - ${error}`}</div>
                        )}

                        {users && (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">NAME</th>
                                        <th scope="col">ROLE</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invites && invites.map(
                                        (invite, index) => (
                                            <tr key={invite.id}>
                                                <td className="with-image">
                                                    <img src="https://saas.nyc3.digitaloceanspaces.com/img/profile.png" />
                                                    <span className="title">{invite.email}</span>
                                                </td>
                                                <td><span className="badge text-bg-info">INVITED</span></td>
                                                <td>
                                                    <div className="dropdown">
                                                        <DropdownLink className="options">
                                                            <span className="glyph glyph-more-vert"></span>
                                                        </DropdownLink>
                                                        <ul className="dropdown-menu" aria-labelledby="options">
                                                            <li>
                                                                <Confirm className="dropdown-item" title="Resend invite?" body="This will resend the user invite?" onSubmit={() => onResend(invite.id)}>Resend</Confirm>
                                                            </li>
                                                            <li>
                                                                <Confirm className="dropdown-item text-danger" title="Revoke invite?" body="This will revoke the users invite?" onSubmit={() => onRevoke(invite.id, index)}>Revoke</Confirm>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}

                                    {users.map(
                                        (user, index) => (
                                            <tr key={user.id}>
                                                <td className="with-image">
                                                    <img src={getProfilePhoto(user)} />
                                                    
                                                    <span className="title">{user.firstName} {user.lastName}</span>
                                                    <div>{user.email}</div>
                                                </td>
                                                <td><span className="badge text-bg-primary text-uppercase">{user.role}</span></td>
                                                <td>
                                                    {!user.owner &&
                                                        <div className="dropdown">
                                                            <DropdownLink className="options">
                                                                <span className="glyph glyph-more-vert"></span>
                                                            </DropdownLink>
                                                            <ul className="dropdown-menu" aria-labelledby="options">
                                                                {user.role != 'admin' && 
                                                                    <li>
                                                                        <Confirm className="dropdown-item" title="Make admin?" body="This will make this user an admin?" onSubmit={() => onMakeAdmin(user.id)}>Make admin</Confirm>
                                                                    </li>
                                                                }
                                                            
                                                                {user.role == 'admin' && 
                                                                    <li>
                                                                        <Confirm className="dropdown-item" title="Remove admin?" body="This will remove logo?" onSubmit={() => onRemoveAdmin(user.id)}>Remove admin</Confirm>
                                                                    </li>
                                                                }
                                                                
                                                                <li>
                                                                    <Confirm className="dropdown-item text-danger" title="Remove user?" body="This will remove the user permanently?" onSubmit={() => onRemove(user.id, index)}>Remove</Confirm>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                        )}
                        
                    </div>
                </div>

            </div>

        </div>
       
    )
}

export default Users;