import config from 'config';
import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers';

const authUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

function login(email, password) {

    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const requestOptions = {
        method: 'POST',
        body: formData
    };

    return fetch(`${config.apiDomain}/api/auth/login`, requestOptions)
        .then(response => handleResponse(response, true))
        .then(user => {
            update(user);

            return user;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    authUserSubject.next(null);
}

function update(user) {
    // update user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    authUserSubject.next(user);
}

function updateAccount(account) {
    // update user accounts in local storage to keep user logged in between page refreshes
    const user = authUserSubject.value;
    user.accounts = [account];
    localStorage.setItem('user', JSON.stringify(user));
    authUserSubject.next(user);
}

function canAccess(user, accountId) {
    if (!user) {
        return false
    }

    if (!accountId) {
        return false;
    }

    if (!user.accounts.includes(accountId)) {
        return false;
    }

    return true;
}

export default {
    login,
    logout,
    update,
    updateAccount,
    canAccess,
    observable: authUserSubject.asObservable(),
    get authUser() { return authUserSubject.value }
};