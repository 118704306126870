import React, { forwardRef } from 'react';
import DefaultModalFooter from './DefaultModalFooter';
import DefaultModalHeader from './DefaultModalHeader';

const ModalComponent = forwardRef(function ModalComponent({
    title = '',
    close,
    confirm,
    confirmText,
    content,
    header = { type: DefaultModalHeader, props: {} },
    footer = { type: DefaultModalFooter, props: {} },
    disabled
}, ref) {
    return (
        <div className="modal fade" tabIndex="-1" ref={ref}>
            <div className="modal-dialog">
                <div className="modal-content">
                    {header?.type ?
                        <header.type {...header.props} disabled={disabled} title={title} close={close} /> :
                        null
                    }
                    <div className="modal-body">
                        {content?.type ?
                            <content.type {...content.props} disabled={disabled} close={close} confirm={confirm} /> :
                            null
                        }
                    </div>
                    {footer?.type ?
                        <div className="modal-footer">
                            <footer.type {...footer.props} disabled={disabled} close={close} confirm={confirm} confirmText={confirmText} />
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
});

export default ModalComponent;
