import React from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { InviteData } from '../../data';
import { Navigation } from '../../components';

function Invite({...props}) {
    const navigate = useNavigate();
    const [account] = useOutletContext();
    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const onSubmit = (data, event) => {
        InviteData.create(account.id, data)
            .then((response) => {
                navigate('/' + account.id + '/users');
            })
            .catch((err) => {
                
            })
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    return (
        <div className="main-content">

            <div className="container">
                
                <div className="page-header condensed">
                    <div className="row">
                        <div className="col-9">
                            <h1>Invite User</h1>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        
                        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input 
                                    type="text" 
                                    className={
                                        cn({'form-control': true, 'is-invalid': errors.email})
                                    } 
                                    {...register('email', { 
                                                required: true,
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Entered value does not match email format"
                                                }
                                            }
                                        )}
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                            </div>
                            
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary">INVITE</button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default Invite;