
import { useContext } from 'react';
import { ModalContext } from '/src/components/modal';

const useModal = () => {
    const context = useContext(ModalContext);
    return context;
}

export default useModal;
