import React, { Suspense, useEffect, useState } from 'react';
import { useOutletContext, useParams } from "react-router-dom";
import { CoursesData } from '../../data';
import { PageHeader } from '../../components';
import ChapterCard from './features/ChapterCard';
import { ProfileData } from '/src/data';
const VideoChapter = React.lazy(() => import(/* webpackChunkName: "VideoChapter" */"/src/pages/courses/features/VideoChapter"));
// import VideoChapter from './features/VideoChapter';
const SlidesChapter = React.lazy(() => import(/* webpackChunkName: "SlidesChapter" */"/src/pages/courses/features/SlidesChapter"));
//import SlidesChapter from './features/SlidesChapter';
import QuizChapter from './features/QuizChapter';
import CourseComplete from './features/CourseComplete';
import YouTubeChapter from './features/YouTubeChapter';
import DownloadsChapter from './features/DownloadsChapter';

function Course() {

    const [account] = useOutletContext();
    const { courseId } = useParams();
    const [userCourse, setUserCourse] = useState();

    const {
        data: course,
        courseLoading,
        courseError
    } = CoursesData.useReadCourse(account.id, courseId);

    const {
        data: userCourses,
        loading: userCoursesLoading,
        error: userCoursesError
    } = ProfileData.useUserCourses();

    useEffect(() => {
        if (userCourses === null || userCoursesLoading === true) {
            return;
        }

        const enrolment = userCourses?.find((enrolment) => enrolment.courseId === parseInt(courseId));
        if (enrolment) {
            setUserCourse(enrolment)
        } else {
            ProfileData.enrolUserInCourse(account.id, { courseId })
                .then((response) => setUserCourse(response))
                .catch((err) => console.log(err))
        };

    }, [userCourses, userCoursesLoading]);

    const proceedToNextChapter = () => {
        const chapterCount = course.chapters.length;

        const completed = chapterCount === userCourse.chapter ? 1 : 0;
        const progress = Math.round(100 * userCourse.chapter / chapterCount);
        const chapter = Math.min(userCourse.chapter + 1, chapterCount);

        ProfileData.updateUserCourse(account.id, courseId, { completed, progress, chapter })
            .then((response) => setUserCourse(response))
            .catch((err) => console.log(err))
    }

    const restartCourse = () => {
        ProfileData.updateUserCourse(account.id, courseId, {
            completed: 0,
            progress: 0,
            chapter: 1
        })
            .then((response) => setUserCourse(response))
            .catch((err) => console.log(err))
    }

    const chapter = (course && userCourse) ? course.chapters[userCourse.chapter - 1] : undefined;

    return (

        <div className="main-content">
            <div className="container-fluid">

                {courseLoading && (
                    <div>A moment please...</div>
                )}

                {courseError && (
                    <div>There is a problem fetching the data</div>
                )}

                {course &&
                    <>
                        <PageHeader title={course.name}></PageHeader>

                        <div className={course.category.className}>
                            <div className="row">
                                <div className="col-lg-8">
                                    {userCourse?.completed
                                        ? <CourseComplete restartCourse={restartCourse} />
                                        : <ChapterWrapper chapterId={chapter?.id} proceedToNextChapter={proceedToNextChapter} />
                                    }
                                </div>
                                <div className="col-lg-4">
                                    <section>
                                        <h4>Course Details</h4>
                                        {course.description}
                                    </section>
                                    {course.chapters.length > 0 &&
                                        <section>
                                            <h4>
                                                Chapters
                                                <span className="h5 float-end">
                                                    <i className="glyph-timer me-2"></i>
                                                    {course.duration}
                                                </span>
                                            </h4>

                                            {course.chapters.map(
                                                (chapter, index) =>
                                                    <ChapterCard
                                                        key={chapter.id}
                                                        chapter={chapter}
                                                        index={index}
                                                        current={userCourse?.chapter === (index + 1)}
                                                        completed={userCourse?.chapter > (index + 1) || userCourse?.completed}
                                                    />
                                            )}

                                        </section>
                                    }
                                </div>
                            </div>
                        </div>

                    </>
                }

            </div>
        </div>
    )
}

function ChapterWrapper({ chapterId, proceedToNextChapter }) {
    const [account] = useOutletContext();
    const { courseId } = useParams();

    const {
        data: chapter,
        chapterLoading,
        chapterError
    } = CoursesData.useReadChapter(account.id, courseId, chapterId);

    if (chapterLoading) {
        return <>Loading data...</>
    }

    switch (chapter?.type) {
        case "video":
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <VideoChapter chapter={chapter} proceedToNextChapter={proceedToNextChapter} />
                </Suspense>
            );
        case "slides":
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <SlidesChapter chapter={chapter} proceedToNextChapter={proceedToNextChapter} />
                </Suspense>
            );
        case "quiz":
            return <QuizChapter chapter={chapter} proceedToNextChapter={proceedToNextChapter} />
        case "youtube":
            return <YouTubeChapter chapter={chapter} proceedToNextChapter={proceedToNextChapter} />
        case "downloads":
            return <DownloadsChapter chapter={chapter} proceedToNextChapter={proceedToNextChapter} />
        default:
            return <></>
    }
}

export default Course;
