import React from "react";

const NotFound = () => {
    return (
        <div style={{ display: "flex" }}>
            <div
                style={{
                    padding: "0 10% 0 10%",
                    width: "80%",
                    margin: "auto",
                    background: "#ffffff"
                }}
            >
                Sorry page not found
            </div>
        </div>
    );
};

export default NotFound;
