import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { useWeb3Modal, Web3Modal } from '@web3modal/react'
import {
    configureChains,
    createConfig,
    useAccount,
    useBalance,
    useDisconnect,
    WagmiConfig,
} from 'wagmi'
import { bsc, mainnet } from 'wagmi/chains'
import config from 'config';

import { AccountData, ProfileData } from '/src/data';
import AuthService from '/src/services/AuthService';

const chains = [bsc, mainnet]
const BSC_MAINNET_CHAIN_ID = 56

const { publicClient } = configureChains(
    chains, [w3mProvider({ projectId: config.walletConnectProjectId })]
)
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId: config.walletConnectProjectId, version: 2, chains }),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

function RegisterWallet() {
    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <div className="container">
                    <div className="row">
                        <div className="offset-md-3 col-md-6">
                            <div className="mt-5">
                                <h1 className="text-center">Connect a Wallet</h1>
                                <ConnectWalletCard />

                                <div className="row">
                                    <div className="offset-md-3 col-md-6 text-center">
                                        <Link to="/register/plan">Select a different plan instead.</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WagmiConfig>

            <Web3Modal projectId={config.walletConnectProjectId} ethereumClient={ethereumClient} />
        </>
    );
}

function ConnectWalletCard() {

    const { open, close } = useWeb3Modal()
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(null);
    const [userWallet, setUserWallet] = useState(null);
    let { state } = useLocation();
    const accountId = state?.account;

    const { address, connector: activeConnector, isConnected } = useAccount()
    const { disconnect } = useDisconnect()

    const { data: balance } = useBalance({
        address,
        chainId: BSC_MAINNET_CHAIN_ID,
        token: config.thonicAddress
    })
    const canContinue = balance?.value >= BigInt(config.minThonicRequired);

    const registerUserWallet = () => {
        ProfileData.createUserVerifiedWallet({
            chainId: BSC_MAINNET_CHAIN_ID,
            network: 'Binance Smart Chain Mainnet',
            address: address,
        })
            .then(response => {
                if (typeof response === "object") {
                    setUserWallet(response);
                }
            });
    };

    useEffect(() => {
        if (userWallet) {
            const accountPromise = accountId
                ? AccountData.update(accountId, { plan: 'holder' })
                : AccountData.create({ plan: 'holder' });

            accountPromise.then(response => {
                if (typeof response === "object") {
                    AuthService.updateAccount(response);
                    setRedirect(`/${response.id}`)
                }
            });
        }
    }, [accountId, userWallet]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect]);

    const thonicRequiredForDisplay = useMemo(() => {
        const withoutDecimals = BigInt(config.minThonicRequired) / BigInt(1000000000);
        return withoutDecimals.toLocaleString();
    }, [config.minThonicRequired])

    const getStepCompleteIcon = (complete) => {
        if (complete) {
            return <span className='glyph-check text-success me-2 mt-1'></span>
        }
        return <span className='glyph-close text-danger me-2 mt-1'></span>
    }

    if (isConnected) {
        return (
            <div className="card bg-light mb-3">
                <div className="card-body d-md-flex flex-row">
                    <div className='display-6 me-3 mb-3'>
                        <span className='glyph-cube-alt p-3 d-inline-block text-bg-secondary rounded'></span>
                    </div>
                    <div className='flex-grow-1'>
                        <ul className='list-unstyled'>
                            <li className='d-flex align-items-start'>
                                {getStepCompleteIcon(activeConnector)}
                                <div>Connected to {activeConnector?.name}</div>
                            </li>
                            <li className='d-flex align-items-start'>
                                {getStepCompleteIcon(address)}
                                <div>Wallet address: {ellipsizeAddress(address)}</div>
                            </li>
                            <li className='d-flex align-items-start'>
                                {getStepCompleteIcon(canContinue)}
                                <div>
                                    <div>Thonic Balance: {balance && balance.formatted}</div>
                                    {(balance && !canContinue) &&
                                        <div
                                            className='text-danger text-decoration-underline'
                                        >Not enough THONIC for membership</div>}
                                </div>
                            </li>
                            <li className='d-flex align-items-center'>
                                {canContinue &&
                                    <button
                                        className='btn btn-primary mt-3'
                                        onClick={() => registerUserWallet()}
                                    >Continue</button>}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <button className='btn btn-link text-danger' onClick={disconnect}>Disconnect</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="card bg-light mb-3">
            <div className="card-body text-center">
                <div className='lead mb-3'>
                    To access the Thonic beta you'll need to be a THONIC holder with at least {thonicRequiredForDisplay} THONIC in your wallet. Connect your wallet now to get access.
                </div>
                <button
                    className='btn btn-primary btn-lg mb-3'
                    onClick={() => open()}
                >Connect wallet</button>
            </div>
        </div>
    );

}

function ellipsizeAddress(address) {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
}

export default RegisterWallet;
