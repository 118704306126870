import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AccountData } from '/src/data';
import AuthService from '/src/services/AuthService';
import cn from "classnames";

const registerOptions = {
    plan: {
        required: true,
    },
};

function RegisterPlan() {
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        AccountData.create(data)
            .then(response => {
                if (typeof response === "object") {
                    AuthService.updateAccount(response);
                    setRedirect(`/${response.id}`)
                }
            });
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect]);

    return (
        <div className="container">
            <div className="row">
                <div className="offset-md-3 col-md-6">

                    <div className="mt-5">
                        <h3 className="text-center">Select Plan</h3>
                        <div className="card bg-light mb-3">
                            <div className="card-body" >

                                <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="card border border-primary">
                                                <div className="card-body">
                                                    <h5 className="card-title">Free</h5>
                                                    <div className="text-center">
                                                        <span className="display-1">$0</span>
                                                        <span className="text-muted">/ month</span>
                                                    </div>
                                                    <p className="card-text">Get access to limited set of features.</p>

                                                    <div className="form-check">
                                                        <input
                                                            className={cn({ 'form-check-input': true, 'is-invalid': errors.plan })}
                                                            type="radio"
                                                            value="free"
                                                            id="freePlan"
                                                            {...register('plan', registerOptions.plan)}
                                                        />
                                                        <label className="form-check-label" htmlFor="freePlan">
                                                            Select free plan
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card border border-primary opacity-25">
                                                <div className="card-body">
                                                    <h5 className="card-title">Paid</h5>
                                                    <div className="text-center">
                                                        <span className="display-1">$9</span>
                                                        <span className="text-muted">/ month</span>
                                                    </div>
                                                    <p className="card-text">Get unlimited access to all features.</p>

                                                    <div className="form-check">
                                                        <input
                                                            className={cn({ 'form-check-input': true, 'is-invalid': errors.plan })}
                                                            type="radio"
                                                            value="paid"
                                                            id="paidPlan"
                                                            disabled={true}
                                                            {...register('plan', registerOptions.plan)}
                                                        />
                                                        <label className="form-check-label" htmlFor="paidPlan">
                                                            Select paid plan
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                        />
                                    </div>

                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary">SUBMIT</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default RegisterPlan;
