import AuthService from '../services/AuthService';

function authHeader(accountId) {
    // return authorization header with jwt token or accountId
    
    let headers = {};
    
    if (accountId)
        headers['Account-Id'] = accountId;
   
    const authUser = AuthService.authUser;
    if (authUser && authUser.jwt) {
        headers.Authorization = `Bearer ${authUser.jwt}`;
    }

    return headers;
}

export default authHeader;