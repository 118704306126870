import React, { useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { ReportData } from "/src/data";
import { AUTHOR_TYPES, REPORT_STATUS, SORTS } from "./constants";
import { Filters, PageHeader } from '../../components';
import { getProfilePhoto } from '../../helpers';
import cn from "classnames";

function ReportCardShell({ status, url, isDisabledReportCard, children }) {

    const cardClassNames = cn({
        'card': true,
        'card-report': true,
        'card-match-height-4': true,
        'mb-4': true,
        'card-pending': ([REPORT_STATUS.PENDING, REPORT_STATUS.DRAFT].includes(status) || isDisabledReportCard)
    });

    if ([REPORT_STATUS.PENDING, REPORT_STATUS.DRAFT].includes(status) || isDisabledReportCard) {
        return (<div className={cardClassNames}>{children}</div>)
    }

    return (<Link className={cardClassNames} to={url}>{children}</Link>)
}

const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

function ReportsCard({ account, report: { id, status, isFree, symbol, icon, thumbnail, name, excerpt, updated, authors = [] } }) {
    const hasElevatedAccount = account.plan != 'free';
    const isDisabledReportCard = !hasElevatedAccount && !isFree;

    const [updateDate, _] = updated.split(" ");
    const [year, month, day] = updateDate.split("-");
    const dateObject = new Date(year, month - 1, day);

    let dateText = 'Coming Soon';
    if (status == REPORT_STATUS.PUBLISHED) {
        dateText = dateObject.toLocaleDateString('en', dateOptions)
    }
    if (isDisabledReportCard) {
        dateText = 'Not Available with free account'
    }

    return (
        <ReportCardShell url={`/${account.id}/reports/${id}`} status={status} isDisabledReportCard={isDisabledReportCard}>
            <img className="card-img-top img-fluid" src={thumbnail} />
            <div className="card-body">
                <div className="card-symbol"><img src={icon} /></div>
                <h5 className="card-title">
                    {name} <span className="badge bg-secondary">{symbol}</span>
                    <span className="card-date">{dateText}&nbsp;</span>
                </h5>

                <p className="card-text">{excerpt}</p>
            </div>
            <div className="card-footer">
                {authors.filter(author => author.type === AUTHOR_TYPES.AUTHOR).map((author, index) => {
                    return (
                        <React.Fragment key={index}>
                            <label key={author.id} className="card-author">
                                <img src={getProfilePhoto(author)} />
                                {`${author.firstName} ${author.lastName}`}
                            </label>
                        </React.Fragment>
                    )
                })}
            </div>
        </ReportCardShell>
    )
}

function Reports() {
    const [account] = useOutletContext();

    const [sort, setSort] = useState(SORTS[0]?.value);
    const [filter, setFilter] = useState(null);

    const { data: reports, loading, error } = ReportData.useReadReports(account.id, sort, filter);
    const { data: categories } = ReportData.useReadReportCategories(account.id);

    return (
        <div className="main-content">
            <div className="container-fluid">

                <PageHeader title="Reports"></PageHeader>

                <Filters
                    sorts={SORTS}
                    onSort={sort => setSort(sort)}
                    filters={categories}
                    onFilter={filter => setFilter(filter)}
                />

                <div className="row">
                    <div className="col-lg-12">
                        {loading && (
                            <div>A moment please...</div>
                        )}

                        {error && (
                            <div>{`There is a problem fetching the data - ${error}`}</div>
                        )}

                        {reports &&
                            <div className="row">
                                {reports.map((report, index) =>
                                    <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3">
                                        <ReportsCard report={report} account={account} />
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Reports;
