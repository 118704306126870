import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { RegisterData } from '/src/data';
import AuthService from '/src/services/AuthService';
import cn from "classnames";

const registerOptions = {
    firstName: {
        required: "Please enter in a valid name",
        maxLength: { value: 255, message: "Name should be less than 255 characters" }
    },
    lastName: {
        required: "Please enter in a valid name",
        maxLength: { value: 255, message: "Name should be less than 255 characters" }
    },
    email: {
        required: {
            value: true,
            message: "Please enter an email"
        },
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format"
        }
    },
    password: {
        required: "Please enter a password",
    },
};

function Register() {
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        RegisterData.register(data)
            .then((payload) => {
                AuthService.update(payload);
                setRedirect('/register/wallet');
            })
            .catch((err) => { })
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect]);

    return (
        <div className="container">
            <div className="row">
                <div className="offset-md-3 col-md-6">

                    <div className="mt-5">
                        <h3 className="text-center">Signup</h3>
                        <div className="card bg-light mb-3">
                            <div className="card-body" >

                                <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation">
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className={cn({ 'form-control': true, 'is-invalid': errors.firstName })}
                                            {...register('firstName', registerOptions.firstName)}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className={cn({ 'form-control': true, 'is-invalid': errors.lastName })}
                                            {...register('lastName', registerOptions.lastName)}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="text"
                                            id="email"
                                            className={cn({ 'form-control': true, 'is-invalid': errors.email })}
                                            {...register('email', registerOptions.email)}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="email"
                                            render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            className={cn({ 'form-control': true, 'is-invalid': errors.password })}
                                            {...register('password', registerOptions.password)}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="password"
                                            render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                        />
                                    </div>

                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary">SUBMIT</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Register;
