import React from "react";
import { Link } from "react-router-dom";
import { getProfilePhoto } from '/src/helpers';
import { AUTHOR_TYPES } from "/src/pages/reports/constants";
import cn from "classnames";

const options = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

function ReportsCardMini({ report, account, index }) {
    const hasElevatedAccount = account.plan != 'free';
    const isDisabledReportCard = !hasElevatedAccount && !report?.isFree;

    const [updateDate, _] = report.updated.split(" ");
    const [year, month, day] = updateDate.split("-");
    const dateObject = new Date(year, month - 1, day);

    const classNames = cn({
        'card': true,
        'card-report': true,
        'card-report-mini': true,
        'text-decoration-none': true,
        'mb-5': index == 0,
        'mb-4': index != 0,
        'opacity-50': isDisabledReportCard
    });

    const reportLink = isDisabledReportCard ? '#' : `/${account.id}/reports/${report.id}`;
    const title = isDisabledReportCard ? 'Not available with free account.' : '';

    return (
        <Link to={reportLink} className={classNames} title={title}>

            <div className="card-body">
                <div className="card-symbol"><img src={report.icon} /></div>
                <div className="card-status">{report.updated === report.created ? "NEW" : "UPDATED"}</div>

                <h5 className="card-title">
                    {report.name} Report<span className="badge bg-secondary">{report.symbol}</span>
                    <span className="card-date">{dateObject.toLocaleDateString("en", options)}</span>
                </h5>

                {report.authors.filter(author => author.type === AUTHOR_TYPES.AUTHOR).map((author, index) => {
                    return (
                        <React.Fragment key={index}>
                            <label key={author.id} className="card-author">
                                <img src={getProfilePhoto(author)} />
                                {`${author.firstName} ${author.lastName}`}
                            </label>
                        </React.Fragment>
                    )
                })}
            </div>
        </Link>
    );


}

export default ReportsCardMini;
