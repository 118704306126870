import React from "react";
import ReportsCardMini from "./features/ReportsCardMini";
import { ReportData } from "/src/data";

function ReportsWidget({ account }) {
    const { data: reports, loading, error } = ReportData.useReadReports(account.id, null, null, 2);

    return (
        <div className="card-wrapper">
            {loading && (
                <div>A moment please...</div>
            )}

            {error && (
                <div>{`There is a problem fetching the data - ${error}`}</div>
            )}

            {reports &&
                reports.map((report, index) =>
                    <ReportsCardMini key={index} index={index} report={report} account={account}></ReportsCardMini>
                )
            }
        </div>
    );
}

export default ReportsWidget;
