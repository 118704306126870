import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import cn from 'classnames';
import AuthService from '/src/services/AuthService';
import { ChainsData, ProfileData } from '/src/data';
import { Confirm, PageHeader } from '/src/components';
import useModal from "/src/hooks/useModal";


function Wallets() {
    const user = AuthService.authUser;
    const hasElevatedAccount = user.accounts.some(account => account.plan == 'holder' || account.plan == 'paid');
    const account = user.accounts.find(account => account.plan == 'free');
    const { modal } = useModal();
    const methods = useForm();
    const [wallets, setWallets] = useState([]);
    const { data, loading, error } = ProfileData.useUserWallets();
    const { chains } = ChainsData.useReadChains();

    useEffect(() => {
        if (data) {
            setWallets(data);
        }
    }, [data]);

    function onRemoveWallet(walletId) {
        return ProfileData.deleteUserWallet(walletId)
            .then((response) => {
                if (response) {
                    setWallets(wallets.filter(wallet => wallet.id !== walletId))
                }
            });
    }

    function onSubmitWalletForm(data) {
        if ('id' in data && data.id !== undefined) {
            // Update
            return ProfileData.updateUserWallet(data.id, data)
                .then(response => {
                    if (typeof response === "object") {
                        setWallets(
                            wallets.map(wallet => wallet.id === data.id ? response : wallet)
                        );
                    }
                });
        }

        // Create
        return ProfileData.createUserWallet(data)
            .then(response => {
                if (typeof response === "object") {
                    setWallets([...wallets, response])
                }
            });
    }

    async function onModalConfirm() {
        const result = await methods.trigger();
        return result
            ? methods.handleSubmit(onSubmitWalletForm)()
            : Promise.resolve(false);
    }

    function onModalClose() {
        methods.reset();
    }

    function showWalletModal(wallet = undefined) {
        modal(WalletForm,
            {
                methods,
                onSubmit: onSubmitWalletForm,
                wallet,
            },
            {
                confirm: onModalConfirm,
                close: onModalClose,
                confirmText: "Save",
                title: wallet ? "Edit Wallet" : "Add Wallet",
            })
    }

    if (!hasElevatedAccount && account) {
        return (
            <div className="main-content">
                <div className="container-lg">
                    <PageHeader title="Wallets"></PageHeader>
                    <h4>
                        Your account does not include wallet tracking.<br />
                    </h4>
                    <p>
                        To unlock this feature, <Link to="/register/wallet"
                            state={{ account: account.id }} className="alert-link"
                        >connect a wallet</Link> which contains Thonic tokens.
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="main-content">
            <div className="container-lg">

                <PageHeader title="Wallets"></PageHeader>

                <p>
                    <button className="btn btn-primary" onClick={showWalletModal}>Add wallet</button>
                </p>

                {loading && (
                    <div className="row">
                        <div className="col-lg-6">A moment please...</div>
                    </div>
                )}


                {error && (
                    <div>{`There is a problem fetching the data - ${error}`}</div>
                )}

                {wallets?.length > 0 &&
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Network</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {wallets.map((wallet, index) => 
                                <tr key={index}>
                                    <td className="align-middle">{wallet.name?.length ? wallet.name : "Wallet " + (index + 1)}</td>
                                    <td className="align-middle">{wallet.address}</td>
                                    <td className="align-middle">{ChainsData.getChainLabel(chains, wallet.chainId)} ({wallet.chainId})</td>
                                    <td className="align-middle">
                                        <button
                                            onClick={() => showWalletModal(wallet)}
                                            className="btn btn-secondary me-2">
                                            <span className="glyph-edit"></span>
                                        </button>
                                        <Confirm className="btn btn-danger"
                                            title="Remove wallet?"
                                            body="This will remove the wallet permanently?"
                                            onSubmit={() => onRemoveWallet(wallet.id)}
                                        ><span className="glyph-trash"></span></Confirm>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

const registerOptions = {
    name: {
        maxLength: { value: 128, message: "Name should be less than 128 characters" }
    },
    address: {
        required: "Please enter in a valid wallet address",
        maxLength: { value: 512, message: "Address should be less than 512 characters" }
    },
    chainId: {
        required: "Please select a network",
    },
};

function WalletForm({ methods, wallet, onSubmit }) {

    const { handleSubmit, register, formState: { errors }, setValue } = methods;
    const { chains } = ChainsData.useReadChains();

    useEffect(() => {
        if (wallet) {
            setValue("id", wallet.id);
            setValue("name", wallet.name);
        }
    }, [wallet]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            <input type="hidden" {...register('id')} />
            <div className="mb-3">
                <label htmlFor="wallet-name" className="form-label">Name <em className='text-muted'>(optional)</em></label>
                <input
                    type="text"
                    id="wallet-name"
                    className={
                        cn({ 'form-control': true, 'is-invalid': errors.name })
                    }
                    {...register('name', registerOptions.name)}
                />
                <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="wallet-address" className="form-label">Address</label>
                {wallet?.id ?
                    <p>{wallet.address}</p> :
                    <input
                        type="text"
                        id="wallet-address"

                        className={
                            cn({ 'form-control': true, 'is-invalid': errors.address })
                        }
                        {...register('address', registerOptions.address)}
                    />
                }
                <ErrorMessage
                    errors={errors}
                    name="address"
                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="chainId" className="form-label">Network</label>
                {wallet?.id ?
                    <p>{wallet && ChainsData.getChainLabel(chains, wallet.chainId)} ({wallet.chainId})</p> :
                    <select
                        id='chainId'
                        name="chainId"
                        className={
                            cn({ 'form-control': true, 'is-invalid': errors.chainId })
                        }
                        aria-label="Select network"
                        {...register('chainId', registerOptions.chainId)}
                    >
                        <option value="">- Select network -</option>
                        {chains && chains.map((chain) =>
                            <option key={chain.chainId} value={chain.chainId}>{chain.name} ({chain.chainId})</option>)
                        }
                    </select>
                }
                <ErrorMessage
                    errors={errors}
                    name="chainId"
                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                />
            </div>
        </form>
    );
}

export default Wallets;
