import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ReportData, ReportFileData } from "/src/data";
import ReportForm from "./features/ReportForm";
import FileUpload from "./features/FileUpload";

function EditReport() {
    const [account] = useOutletContext();
    const navigate = useNavigate();
    const params = useParams();
    const { data: report, loading, error } = ReportData.useReadReport(account.id, params.reportId);

    const onSubmit = (data, event) => {
        ReportData.update(account.id, params.reportId, data)
            .then((response) => {
                navigate(`/${account.id}/reports/${params.reportId}`);
            })
            .catch((err) => { })
    }

    const onFileSubmit = (data, event) => {
        ReportFileData.create(account.id, params.reportId, data)
            .then((response) => {
                navigate(`/${account.id}/reports/${params.reportId}`);
            })
            .catch((err) => { })
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    return (
        <div className="main-content">
            <div className="container">
                <div className="page-header condensed">
                    <h1>Edit Report</h1>
                </div>

                {loading && (
                    <div>A moment please...</div>
                )}

                {error && (
                    <div>There is a problem fetching the data</div>
                )}

                {report && <ReportForm onSubmit={onSubmit} onError={onError} report={report} />}

                {report && <FileUpload onSubmit={onFileSubmit} onError={onError} />}
            </div>
        </div>
    )
}

export default EditReport;
