import React from "react";
import { useForm } from "react-hook-form";
import { ProfileData } from "/src/data";

export default function VoteForm({ onError, onSubmit, reportId, setIsVoteFormShown }) {
    const { data, loading, error } = ProfileData.useUserReportScore(reportId);
    const { handleSubmit, register, watch, setValue } = useForm({
        values: { score: data?.score?.toString() ?? '10' }
    });

    if (data == null || loading) {
        return <></>
    }

    return (
        <form className="score-report" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="row mb-1">
                <div className="col">
                    <span className="range-slider__value">{watch("score")}</span>
                </div>
                <div className="col-9 g-0">
                    <input {...register("score")}
                        type="range"
                        className="form-range"
                        step="1"
                        min="1"
                        max="10"
                        onInput={(event) => { setValue(event.target.value) }} />
                </div>
            </div>
            <div className="text-end">
                <button
                    className="btn btn-sm btn-link me-2"
                    type="button"
                    onClick={() => setIsVoteFormShown(false)}
                >Cancel</button>
                <button className="btn btn-outline-primary btn-sm" type="submit">Save</button>
            </div>
        </form>

    );
}
