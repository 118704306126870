import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import cn from "classnames";
import ChapterComplete from "./ChapterComplete";

function QuizChapter({ chapter, proceedToNextChapter }) {
    const [userAnswers, setUserAnswers] = useState({});
    const [isQuizComplete, setIsQuizComplete] = useState(false);

    useEffect(() => {
        // Reset state in case next chapter is also quiz
        setIsQuizComplete(false);
    }, [chapter])

    const handleUserAnswer = (e) => {
        setUserAnswers({
            ...userAnswers,
            [e.target.name]: e.target.value,
        });
    }

    const checkAnswers = () => {
        setIsQuizComplete(true);
    }

    const checkIsValid = (question, letter, answer) => {
        if (!isQuizComplete) {
            return false;
        }

        return letter === answer;
    };

    const checkIsInvalid = (question, letter, answer) => {
        if (!isQuizComplete) {
            return false;
        }

        if (!question in userAnswers) {
            return false;
        }

        return letter !== answer && userAnswers[question] === letter;
    };

    if (!chapter) {
        return <section>Loading...</section>;
    }

    return (
        <section>
            {chapter && chapter.name &&
                <h4 className="mb-3">{chapter.name}</h4>
            }

            {chapter &&
                <div className="mb-3">
                    <ReactMarkdown>{chapter?.description}</ReactMarkdown>
                </div>
            }

            {chapter.data.map((question, questionIndex) =>
                <div key={questionIndex} className="card mb-2">
                    <div className="card-body">
                        <h5 className="card-title">{question.question}</h5>
                        {["a", "b", "c", "d"].map((letter, answerIndex) =>
                            <div key={answerIndex} className="form-check">
                                <input
                                    className={
                                        cn({
                                            "form-check-input": true,
                                            "is-invalid": checkIsInvalid(questionIndex, letter, question.answer),
                                            "is-valid": checkIsValid(questionIndex, letter, question.answer)
                                        })
                                    }
                                    type="radio"
                                    name={questionIndex}
                                    id={`q-${questionIndex}-a-${letter}`}
                                    value={letter}
                                    onChange={handleUserAnswer}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`q-${questionIndex}-a-${letter}`}
                                >{question[letter]}</label>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {isQuizComplete
                ? <ChapterComplete proceedToNextChapter={proceedToNextChapter} />
                : <div className="text-center mb-2 py-2">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={checkAnswers}
                    >Check answers</button>
                </div>
            }
        </section>
    );
}

export default QuizChapter;