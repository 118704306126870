import config from 'config';
import { authHeader, handleResponse } from '../helpers';

function read(accountId, id) {

    const requestOptions = { method: 'GET', headers: authHeader(accountId) };

    if (id) {
        return fetch(`${config.apiDomain}/api/invites/read?${new URLSearchParams({id: id}).toString()}`, requestOptions)
            .then(response => handleResponse(response, true));    
    } else {
        return fetch(`${config.apiDomain}/api/invites`, requestOptions)
            .then(response => handleResponse(response, true) );
    }
}

function create(accountId, data) {

    let formData = new FormData();
    formData.append('email', data.email);
    formData.append('role', data.role);
    
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/invites/create`, requestOptions)
        .then(response => handleResponse(response));
}

function resend(accountId, id) {

    let formData = new FormData();
    formData.append('id', id);
    
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/invites/resend`, requestOptions)
        .then(response => handleResponse(response));
}

function revoke(accountId, id) {

    let formData = new FormData();
    formData.append('id', id);
    
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(accountId),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/invites/revoke`, requestOptions)
        .then(response => handleResponse(response));
}

export default {
    read,
    create,
    resend,
    revoke
};