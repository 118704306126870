import React from 'react';

function RegisterBilling() {
    
    return (
        
        <div className="container">
            
            <div className="row">
                <div className="offset-md-3 col-md-6">
                    
                    <div className="mt-5">
                        <h3 className="text-center">Please enter you billing details</h3>
                        <div className="card bg-light mb-3">
                            <div className="card-body" >
                            
                                Sorry but the Alpha is not open to the public 

                            </div>
                        </div>
                    </div>
                        
                </div>
            </div>
        </div>
    );
}

export default RegisterBilling;