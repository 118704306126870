import React from "react";
import { NavLink } from "react-router-dom";
import { ProfileData } from '/src/data';

function ProfileNavigation() {
    const { data: profile, loading, error } = ProfileData.useUserProfile();

    return (
        <div className="navigation justify-content-start">

            {profile && profile.accounts &&
                <div className="ms-3 my-3">
                    {profile.accounts.length > 1 &&
                        <NavLink to="/" className="text-decoration-none">
                            <span className="glyph-chevron-left"></span> <span> Return to your Accounts</span>
                        </NavLink>
                    }
                    {profile.accounts.length == 1 &&
                        <NavLink to={'/' + profile.accounts[0].id} className="text-decoration-none">
                            <span className="glyph-chevron-left"></span> <span> Return to Dashboard</span>
                        </NavLink>
                    }
                </div>
            }

            <ul className="left-side-nav">
                <li className="side-nav-item">
                    <NavLink to="/profile" end className="side-nav-link">
                        <span>Personal Info</span>
                    </NavLink>
                </li>
                <li className="side-nav-item">
                    <NavLink to="/profile/password" className="side-nav-link">
                        <span>Password</span>
                    </NavLink>
                </li>
                <li className="side-nav-item">
                    <NavLink to="/profile/photo" className="side-nav-link">
                        <span>Photo</span>
                    </NavLink>
                </li>
                <li className="side-nav-item">
                    <NavLink to="/profile/wallets" className="side-nav-link">
                        <span>Wallets</span>
                    </NavLink>
                </li>
                <li className="side-nav-item">
                    <NavLink to="/profile/tokens" className="side-nav-link">
                        <span>Tokens</span>
                    </NavLink>
                </li>

            </ul>
        </div>
    );
}

export default ProfileNavigation;
