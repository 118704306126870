
export const AUTHOR_TYPES = Object.freeze({
  AUTHOR: 1,
  CONTRIBUTOR: 2,
});

export const REPORT_STATUS = Object.freeze({
  PUBLISHED: 1,
  PENDING: 2,
  DRAFT: 3,
});

export const SORTS = [
  {
    label: 'Date',
    value: 'updated'
  },
  {
    label: 'Symbol',
    value: 'symbol'
  },
];
