import React from "react";

function PromoWidget({ user, ...props }) {

    return (
        <div className="card card-promo card-match-height-4 mb-4 text-bg-dark">
            <div className="card-body">
                <h2 className="card-title">Thonic Beta Test</h2>
                <a href="mailto:info@thonic.finance" className="text-white">Click here to submit feedback</a>
            </div>
        </div>
    );
}

export default PromoWidget;
