import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import cn from 'classnames';
import { ChainsData, ProfileData, TokensData } from '/src/data';
import { debounce } from '/src/helpers';
import { Confirm, PageHeader } from '/src/components';
import clickOutside from '/src/hooks/clickOutside';
import AuthService from '/src/services/AuthService';

const MIN_CHARACTERS_FOR_AUTOCOMPLETE_SUGGESTIONS = 3;

const registerOptions = {
    address: {
        required: "Please enter in a valid token address",
        maxLength: { value: 512, message: "Address should be less than 512 characters" }
    },
    chainId: {
        required: "Please select a network",
    },
};

function Tokens() {
    const user = AuthService.authUser;
    const hasElevatedAccount = user.accounts.some((account) => account.plan == 'holder' || account.plan == 'paid');
    const account = user.accounts.find(account => account.plan == 'free');
    const { handleSubmit, register, reset, formState: { errors }, setValue, trigger, watch } = useForm();

    // Managing the tokens in a state allows us to add and remove them dynamically
    const [tokens, setTokens] = useState([]);
    const { data, loading, error } = ProfileData.useUserTokens();

    const dropdownRef = useRef(null);
    const [{ address: addressValue, chainId: chainIdValue }, setFormValues] = useState({ address: "", chainId: "" });
    const [showDropdown, setShowDropdown] = useState(false);

    const {
        data: suggestedTokens = [],
        loading: loadingSuggestions
    } = TokensData.useReadTokens(null, null, addressValue, chainIdValue);

    const { chains } = ChainsData.useReadChains();

    // Wrap in useCallback to make sure we memoize the function debounce returns
    const debouncedSetFormValues = useCallback(debounce(setFormValues), []);

    useEffect(() => {
        if (data) {
            setTokens(data);
        }
    }, [data]);

    function onRemoveToken(tokenId) {
        return ProfileData.deleteUserToken(tokenId)
            .then((response) => {
                if (response) {
                    setTokens(tokens.filter(token => token.id !== tokenId))
                }
            });
    }

    function onSubmit(data) {
        return ProfileData.createUserToken(data)
            .then(response => {
                if (typeof response === "object") {
                    setTokens([...tokens, response]);
                    reset();
                }
            });
    }

    function focusForm() {
        setShowDropdown(true);
    }

    function closeDropdown() {
        setShowDropdown(false);
    }

    clickOutside(dropdownRef, closeDropdown);

    React.useEffect(() => {
        const subscription = watch((values, { name, type }) => {
            const changedValue = values[name];
            if (name === "chainId" && changedValue) {
                debouncedSetFormValues(values);
                return;
            }

            if (changedValue?.length >= MIN_CHARACTERS_FOR_AUTOCOMPLETE_SUGGESTIONS) {
                debouncedSetFormValues(values);
                return;
            }

            debouncedSetFormValues({ ...values, [name]: "" });
        });

        return () => subscription.unsubscribe()
    }, [watch]);

    function handleSuggestionClick(suggestion) {
        setValue("address", suggestion.address);
        setValue("chainId", suggestion.chainId);
        trigger();
        closeDropdown();
    }

    if (!hasElevatedAccount && account) {
        return (
            <div className="main-content">
                <div className="container-lg">
                    <PageHeader title="Wallets"></PageHeader>
                    <h4>
                        Your account does not include token tracking.<br />
                    </h4>
                    <p>
                        To unlock this feature, <Link to="/register/wallet"
                            state={{ account: account.id }} className="alert-link"
                        >connect a wallet</Link> which contains Thonic tokens.
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="main-content">
            <div className="container-lg">

                <PageHeader title=" Tracked tokens"></PageHeader>

                {loading && (
                    <div className="row">
                        <div className="col-lg-6">A moment please...</div>
                    </div>
                )}

                <div className="row">
                    <div className="col-lg-12">

                        {error && (
                            <div>{`There is a problem fetching the data - ${error}`}</div>
                        )}

                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation position-relative">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">Network</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tokens && tokens.map((token, index) =>
                                        <tr key={token.id}>
                                            <td className="align-middle token-icon">{token.icon &&
                                                <div><img src={token.icon} /></div>
                                            }</td>
                                            <td className="align-middle">{token.name}<div className="text-muted">{token.symbol}</div></td>
                                            <td className="align-middle">{token.address}</td>
                                            <td className="align-middle">{ChainsData.getChainLabel(chains, token.chainId)} ({token.chainId})</td>
                                            <td className="align-middle">
                                                <Confirm className="btn btn-danger"
                                                    title="Remove token?"
                                                    body="This will remove the token permanently?"
                                                    onSubmit={() => onRemoveToken(token.id)}
                                                ><span className="glyph-trash"></span></Confirm>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td className="align-middle text-center">
                                            <span className="glyph-plus p-2 border border-2 rounded-5"></span>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                id="address"
                                                className={
                                                    cn({ 'form-control': true, 'is-invalid': errors.address })
                                                }
                                                placeholder="Address"
                                                {...register('address', registerOptions.address)}
                                                onFocus={focusForm}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="address"
                                                render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                id='chainId'
                                                name="chainId"
                                                className={
                                                    cn({ 'form-control': true, 'is-invalid': errors.chainId })
                                                }
                                                aria-label="Select network"
                                                {...register('chainId', registerOptions.chainId)}
                                                onFocus={focusForm}
                                            >
                                                <option value="">- Select network -</option>
                                                {chains && chains.map((chain) =>
                                                    <option key={chain.chainId} value={chain.chainId}>{chain.name} ({chain.chainId})</option>)
                                                }
                                            </select>
                                            <ErrorMessage
                                                errors={errors}
                                                name="chainId"
                                                render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                            />
                                        </td>
                                        <td>
                                            <button className="btn btn-primary" type="submit">Save</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {showDropdown && (
                                <div className="dropdown-menu w-100 show" ref={dropdownRef}>
                                    <p className="text-muted ps-3"><em>SUGGESTED</em></p>
                                    {suggestedTokens && suggestedTokens.map((suggestion, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className="dropdown-item"
                                            value={suggestion.id}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            <div className="card card-token border-0">
                                                <div className="card-body p-1">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="card-title">
                                                                <div className="card-symbol">
                                                                    {suggestion.icon && <img src={suggestion.icon} />}
                                                                </div>
                                                                {suggestion.name}
                                                                <div className="text-muted">{suggestion.symbol}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            Address<div className="text-muted text-wrap">{suggestion.address}</div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            Chain<div className="text-muted text-wrap">{ChainsData.getChainLabel(chains, suggestion.chainId)} ({suggestion.chainId})</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tokens;
