import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

function CourseItem({ course, account }) {

    const classNames = cn({
        'd-block': true,
        'course-item': true,
        'text-decoration-none': true,
        [`${course.category.className}`]: true
    });

    return (
        <Link to={`/${account.id}/courses/${course.id}`} className={classNames}>

            <div className="row gx-2 mb-2">
                <div className="col-2">
                    <img className="icon" src={course.category.icon} />
                </div>
                <div className="col-10">
                    <div className="category">{course.category.name}</div>
                    <h5 className="title">{course.name}</h5>
                </div>
            </div>

            <div className="row  gx-2">
                <div className="col-8 align-self-center">
                    {course.progress > 0 &&
                        <div className="progress"
                            role="progressbar"
                            aria-label="Course Progress"
                            aria-valuenow={course.progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            <div className="progress-bar" style={{ width: course.progress + '%' }}></div>
                        </div>
                    }
                </div>

                <div className="col-4">
                    <div className="status">{course.progress > 0 ? 'CONTINUE' : 'START'} <span className="glyph-chevron-right"></span></div>
                </div>
            </div>
        </Link>
    );
}

export default CourseItem;
