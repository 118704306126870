
import React, { useRef, useEffect } from 'react';
import NeuronsCanvas from '../helpers/NeuronsCanvas';

const Neurons = props => {
    const canvasRef = useRef(null)
  
	useEffect(() => {
		
		const canvas = canvasRef.current
		let context = canvas.getContext('2d');

		let animationFrameId;
		
		canvas.style.width = '100%';
		canvas.style.height = window.innerHeight + 'px';
		canvas.width  = context.canvas.offsetWidth;
		canvas.height = window.innerHeight;

		NeuronsCanvas.createPoints(context);
		NeuronsCanvas.drawFrame(context);
		
		//Our draw came here
		const render = (timestamp) => {
			NeuronsCanvas.animate(timestamp, context)
			animationFrameId = window.requestAnimationFrame(render)
		}
		render()
		
		return () => {
			window.cancelAnimationFrame(animationFrameId)
		}
	}, [])
  
  return <canvas ref={canvasRef} {...props}/>
};

export default Neurons;