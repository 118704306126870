import React from 'react'

export default function DefaultModalHeader({ title, close, disabled }) {
    return (
        <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                disabled={disabled}
                aria-label="Close"
                onClick={close}
            ></button>
        </div>
    )
}