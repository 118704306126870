import React from "react";
import { Line } from 'react-chartjs-2';

const options = {
    responsive: true,
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        }
    },
};

const green = 'rgb(70, 123, 83)';
const red = 'rgb(255, 99, 132)';

function Token({ token }) {

    return (
        <div className="card card-token mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card-title">
                            <div className="card-symbol"><img src={token.icon} /></div>
                            {token.name}
                            <div className="text-muted">{token.symbol}</div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        Price
                        <div className="text-muted">{token.price ? token.price : <em>Processing...</em>}</div>
                    </div>
                    <div className="col-md-3">
                        Tokens
                        <div className="text-muted">{token.amount != null ? token.amount.toLocaleString('en-US') : <em>Processing...</em>}</div>
                    </div>
                    <div className="col-md-2 text-end">
                        {token.history.length > 0 &&
                            <Line
                                options={options}
                                data={{
                                    labels: token.history.map(a => a.created),
                                    datasets: [
                                        {
                                            data: token.history.map(a => a.price),
                                            borderColor: token.history.at(0).price > token.history.at(-1).price ? red : green,
                                            borderWidth: 2,
                                            backgroundColor: 'rgba(255, 99, 132, 0.5)',

                                        }
                                    ],
                                }}
                            />
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Token;
