import config from 'config';
import { authHeader, handleResponse } from '../helpers';

function forgot(data) {

    let formData = new FormData();
    formData.append('email', data.email);
    
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/password/forgot`, requestOptions).then(handleResponse);
}

function reset(data) {

    let formData = new FormData();
    formData.append('key', data.key);
    formData.append('password1', data.password1);
    formData.append('password2', data.password2);
    
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/password/reset`, requestOptions).then(handleResponse);
}

export default {
    forgot,
    reset
};