import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import MDEditor, { commands } from "@uiw/react-md-editor";
import cn from "classnames";
import { ReportData, UserData } from "/src/data";
import { AUTHOR_TYPES } from "../constants";

const registerOptions = {
    title: {
        required: "Title is required",
        maxLength: { value: 255, message: "Titles should be less than 255 characters" }
    },
    symbol: {
        required: "Symbol is required",
        maxLength: { value: 32, message: "Symbols should be less than 32 characters" }
    },
    analystScore: {
        min: { value: 0, message: "Allowed scores are between 0 and 100" },
        max: { value: 100, message: "Allowed scores are between 0 and 100" }
    }
};

const uploadCommand = {
    name: 'pick-image',
    groupName: 'pick-image',
    buttonProps: { 'aria-label': 'Pick image' },
    icon: (
        <svg viewBox="0 0 1024 1024" width="12" height="12">
            <path fill="currentColor" d="M716.8 921.6a51.2 51.2 0 1 1 0 102.4H307.2a51.2 51.2 0 1 1 0-102.4h409.6zM475.8016 382.1568a51.2 51.2 0 0 1 72.3968 0l144.8448 144.8448a51.2 51.2 0 0 1-72.448 72.3968L563.2 541.952V768a51.2 51.2 0 0 1-45.2096 50.8416L512 819.2a51.2 51.2 0 0 1-51.2-51.2v-226.048l-57.3952 57.4464a51.2 51.2 0 0 1-67.584 4.2496l-4.864-4.2496a51.2 51.2 0 0 1 0-72.3968zM512 0c138.6496 0 253.4912 102.144 277.1456 236.288l10.752 0.3072C924.928 242.688 1024 348.0576 1024 476.5696 1024 608.9728 918.8352 716.8 788.48 716.8a51.2 51.2 0 1 1 0-102.4l8.3968-0.256C866.2016 609.6384 921.6 550.0416 921.6 476.5696c0-76.4416-59.904-137.8816-133.12-137.8816h-97.28v-51.2C691.2 184.9856 610.6624 102.4 512 102.4S332.8 184.9856 332.8 287.488v51.2H235.52c-73.216 0-133.12 61.44-133.12 137.8816C102.4 552.96 162.304 614.4 235.52 614.4l5.9904 0.3584A51.2 51.2 0 0 1 235.52 716.8C105.1648 716.8 0 608.9728 0 476.5696c0-132.1984 104.8064-239.872 234.8544-240.2816C258.5088 102.144 373.3504 0 512 0z" />
        </svg>
    ),
    children: (handle) => {
        const handleImagePicker = (e) => {
            console.log(e.target.src);
            const state = handle.getState();
            const src = e.target.src;
            const modifyText = `![${state.selectedText}](${src})`;
            handle.textApi.replaceSelection(modifyText);
        };
        return (
            <div style={{ width: 320, padding: 10 }}>
                <div className="mb-4">My Custom Toolbar</div>
                <div
                    style={{ width: "50px", height: "50px", background: "yellow", cursor: "pointer" }}
                    className="mb-4"
                    onClick={handleImagePicker}
                >
                    <img className="img-fluid" src="https://picsum.photos/200" />
                </div>
                <button type="button" onClick={() => handle.close()}>Cancel</button>
            </div>
        );
    },
    // execute: (state, api, src) => {
    //     console.log(src);
    //     console.log('> execute state: >>>>>', state);

    //     const modifyText = `![${state.selectedText}](${src})`;
    //     api.replaceSelection(modifyText);
    // },
}

function ReportForm({ onSubmit, onError, report }) {
    const selectedAuthors = report
        ? report.authors
            .filter(author => author.type === AUTHOR_TYPES.AUTHOR)
            .map(author => author.id.toString())
        : [];
    const selectedContributors = report
        ? report.authors
            .filter(author => author.type === AUTHOR_TYPES.CONTRIBUTOR)
            .map(author => author.id.toString())
        : [];
    const selectedCategories = report
        ? report.categories.map(category => category.id.toString())
        : [];

    const [account] = useOutletContext();
    const formOptions = {};
    if (report) {
        formOptions.values = {
            "title": report.title,
            "symbol": report.symbol,
            "content": report.content,
            "analystScore": report.analystScore,
        }
    }

    const { control, formState: { errors }, handleSubmit, register, setValue } = useForm(formOptions);
    const {
        data: users,
        loading: usersLoading,
        error: usersError
    } = UserData.useReadUsers(account.id);
    const {
        data: categories,
        loading: categoriesLoading,
        error: categoriesError
    } = ReportData.useReadReportCategories(account.id);

    useEffect(() => {
        setValue("authors", selectedAuthors);
        setValue("contributors", selectedContributors);
    }, [users]);

    useEffect(() => {
        setValue("categories", selectedCategories);
    }, [categories]);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="row">
                <div className="col mb-3">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input
                        type="text"
                        className={
                            cn({ "form-control": true, "is-invalid": errors.title })
                        }
                        {...register("title", registerOptions.title)}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6 mb-3">
                    <label htmlFor="symbol" className="form-label">Symbol</label>
                    <input
                        type="text"
                        className={
                            cn({ "form-control": true, "is-invalid": errors.symbol })
                        }
                        {...register("symbol", registerOptions.symbol)}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="symbol"
                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                    />
                </div>
                <div className="col-sm-6 mb-3">
                    <label htmlFor="analystScore" className="form-label">Score</label>
                    <input
                        type="number"
                        className={
                            cn({ "form-control": true, "is-invalid": errors.analystScore })
                        }
                        {...register("analystScore", registerOptions.analystScore)}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="analystScore"
                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6 col-md-3 mb-3">
                    <label className="form-label">Authors</label>
                    {usersLoading && (
                        <div>Fetching users...</div>
                    )}

                    {usersError && (
                        <div>There is a problem fetching the data</div>
                    )}

                    {users && users.map(user =>
                        <div key={user.id} className="form-check">
                            <input
                                {...register("authors")}
                                className="form-check-input"
                                type="checkbox"
                                value={user.id}
                                id={`author-${user.id}`}
                            />
                            <label className="form-check-label" htmlFor={`author-${user.id}`}>
                                {`${user.firstName} ${user.lastName}`}
                            </label>
                        </div>
                    )}
                </div>
                <div className="col-sm-6 col-md-3 mb-3">
                    <label className="form-label">Contributors</label>
                    {usersLoading && (
                        <div>Fetching users...</div>
                    )}

                    {usersError && (
                        <div>There is a problem fetching the data</div>
                    )}

                    {users && users.map(user =>
                        <div key={user.id} className="form-check">
                            <input
                                {...register("contributors")}
                                className="form-check-input"
                                type="checkbox"
                                value={user.id}
                                id={`contributor-${user.id}`}
                            />
                            <label className="form-check-label" htmlFor={`contributor-${user.id}`}>
                                {`${user.firstName} ${user.lastName}`}
                            </label>
                        </div>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label">Categories</label>
                    {categoriesLoading && (
                        <div>Fetching categories...</div>
                    )}

                    {categoriesError && (
                        <div>There is a problem fetching the data</div>
                    )}

                    {categories && categories.map(category =>
                        <div key={category.id} className="form-check">
                            <input
                                {...register("categories")}
                                className="form-check-input"
                                type="checkbox"
                                value={category.id}
                                id={`category-${category.id}`}
                            />
                            <label className="form-check-label" htmlFor={`category-${category.id}`}>
                                {category.name}
                            </label>
                        </div>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col mb-3">
                    <Controller
                        name="content"
                        control={control}
                        render={({ field }) => <MDEditor
                            {...field}
                            height={400}
                            commands={[
                                commands.bold,
                                commands.italic,
                                commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
                                    name: 'title',
                                    groupName: 'title',
                                    buttonProps: { 'aria-label': 'Insert title' }
                                }),
                                commands.divider,
                                commands.hr,
                                commands.quote,
                                commands.link,
                                commands.image,
                                commands.divider,
                                commands.unorderedListCommand,
                                commands.orderedListCommand,
                                commands.divider,
                                commands.comment,
                                commands.group([], uploadCommand),
                            ]}
                        />}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col mb-3">
                    <button type="submit" className="btn btn-primary">
                        SAVE
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ReportForm;
