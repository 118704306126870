import React from "react";
import CourseItem from "./features/CourseItem";
import { ProfileData } from '/src/data';

function CoursesWidget({ account }) {
    const { data: courses, loading, error } = ProfileData.useUserRecentCourses();

    return (
        <div className="card-wrapper mb-4">
            <h5 className="card-label">Course Activity</h5>
            <div className="card">
                {loading && (
                    <div className="card-body">A moment please...</div>
                )}

                {error && (
                    <div className="card-body">{`There is a problem fetching the data - ${error}`}</div>
                )}

                {courses &&
                    <div className="course-list">
                        {courses.map(
                            (course, index) => (
                                <CourseItem key={index} course={course} account={account}></CourseItem>
                            )
                        )}
                    </div>
                }

            </div>
        </div>
    );
}

export default CoursesWidget;
