import React from 'react';
import AuthService from '../services/AuthService';
import { Neurons } from '../components';
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";

function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const authUser = AuthService.authUser;
    if (authUser) {
        navigate('/');
    }

    const onSubmit = (data, event) => {
        AuthService.login(data.email, data.password)
            .then(user => {  
                const { from } = location.state || { from: { pathname: '/' } };
                navigate(from);

            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    const onError = (errors, event) => {
        console.log(errors, event);
    }

    return (
        <div className="login-page">
            <div className="container-fluid g-0">
                <div className="row g-0 h-100 align-items-center">
                    <div className="col-md-6">
                    
                        <div className="login-form">
                            <h3 className="text-center">Welcome</h3>
                            <div className="row">
                                <div className="col-8 offset-2" >
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input 
                                                type="text" 
                                                className={
                                                    cn({'form-control': true, 'is-invalid': errors.email})
                                                } 
                                                {...register('email', { 
                                                            required: true,
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: "Entered value does not match email format"
                                                            }
                                                        }
                                                    )}
                                            />
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                                <input 
                                                    type="password" 
                                                    className={
                                                        cn({'form-control': true, 'is-invalid': errors.password})
                                                    } 
                                                    {...register('password', { 
                                                                required: true,
                                                                minLength: {
                                                                    value: 8,
                                                                    message: "min length is 12"
                                                                }
                                                            }
                                                        )}
                                                />
                                                {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                        </div>
                                        <div className="d-grid gap-2">
                                            <button type="submit" className="btn btn-primary">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row">
                                <div className="offset-2 col-4">
                                    <Link to="/password/forgot">Forgot Password?</Link>
                                </div>
                                <div className="col-4 text-end">
                                    <Link to="/register">Register</Link>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div className="col-md-6">
                        <div className="login-background">
                            <Neurons className="neurons" />
                            <img className="logo" src="/img/thonic_logo.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default Login;