import config from 'config';
import { handleResponse } from '../helpers';

function register(data) {

    let formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('email', data.email);
    formData.append('password', data.password);

    const requestOptions = {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Accept': 'application/json' },
        body: formData
    };

    return fetch(`${config.apiDomain}/api/register`, requestOptions)
        .then(response => handleResponse(response, true));
}

function registerInvite(data) {

    let formData = new FormData();
    formData.append('token', data.token);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('password', data.password);

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: formData
    };

    return fetch(`${config.apiDomain}/api/register/invite`, requestOptions).then(response => handleResponse(response, true));
}

function registerBilling(data) {

    let formData = new FormData();
    formData.append('token', data.token);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('password', data.password);

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: formData
    };

    return fetch(`${config.apiDomain}/api/register/billing`, requestOptions).then(response => handleResponse(response, true));
}

export default {
    register,
    registerBilling,
    registerInvite
};