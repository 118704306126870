import React from 'react';
import { Link, useOutletContext } from "react-router-dom";
import { PageHeader } from '/src/components';
import { ProfileData } from '/src/data';
import AuthService from '/src/services/AuthService';
import ReportsWidget from './widgets/ReportsWidget';
import BalanceWidget from './widgets/BalanceWidget';
import GrowthWidget from './widgets/GrowthWidget';
import CoursesWidget from './widgets/CoursesWidget';
import TokensWidget from './widgets/TokensWidget';
import PromoWidget from './widgets/PromoWidget';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement
);

function Dashboard() {
    const [account] = useOutletContext();
    const isFreeAccount = account.plan == 'free';
    const authUser = AuthService.authUser;

    const { data: wallets, loading, error } = ProfileData.useUserWallets();


    return (
        <div className="main-content">
            <div className="container-lg">

                <PageHeader title="Dashboard"></PageHeader>

                {isFreeAccount &&
                    <div className="alert alert-warning d-flex align-items-center" role="alert">
                        <i className="glyph-warning me-3"></i>
                        <div>
                            Hi, {authUser.name}! You are on the 'free' plan which has limited
                            dashboard functionality and access to reports. To unlock all these
                            features, <Link to="/register/wallet" state={{ account: account.id }}
                                className="alert-link">connect a wallet</Link> which contains
                            Thonic tokens.
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12">
                                <TokensWidget account={account}></TokensWidget>
                            </div>
                            <div className="col-sm-6">
                                <GrowthWidget account={account} wallets={wallets}></GrowthWidget>
                            </div>
                            <div className="col-sm-6">
                                <PromoWidget account={account}></PromoWidget>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <BalanceWidget account={account} wallets={wallets}></BalanceWidget>

                        <ReportsWidget account={account}></ReportsWidget>

                        <CoursesWidget account={account}></CoursesWidget>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
