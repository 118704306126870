import React from 'react';

function ChapterComplete({ proceedToNextChapter }) {
    return (
        <div className='d-flex flex-column align-items-center pt-5 pb-5'>
            <h2 className='mb-5'>Chapter complete!</h2>
            <div>
                <button className='btn btn-primary' onClick={proceedToNextChapter}>Next</button>
            </div>
        </div>
    );
}

export default ChapterComplete;
