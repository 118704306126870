import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { getProfilePhoto } from "/src/helpers";
import { ReportData } from "/src/data";
import { AUTHOR_TYPES } from "./constants";
import VoteForm from "./features/VoteForm";


function ReportScore({
    report: {
        id: reportId,
        analystScore,
        investorScore,
        investorVoteCount
    }
}) {
    const [account] = useOutletContext();
    const [isVoteFormShown, setIsVoteFormShown] = useState(false);
    const [fetchCount, setFetchCount] = useState(0);
    const [reportScores, setReportScores] = useState({
        id: reportId,
        analystScore,
        investorScore,
        investorVoteCount
    });

    const { data: updatedData } = ReportData.useReadReportScore(account.id, reportId, fetchCount);
    useEffect(() => {
        if (updatedData) {
            setReportScores(updatedData);
        }
    }, [updatedData]);

    const onSubmit = (data, event) => {
        ReportData.createScore(account.id, reportId, data)
            .then((response) => {
                setIsVoteFormShown(false);
                setFetchCount(fetchCount + 1);
            })
            .catch((err) => { });
    };

    const onError = (errors, event) => {
        console.log(errors, event);
    };

    return (
        <div className="reports-scores">
            <div className="row">
                <div className="col">
                    <div className="percent text-end">
                        {reportScores.analystScore != null ?
                            <>{reportScores.analystScore}<sub>%</sub></> :
                            '-'
                        }
                    </div>
                    <div className="text-end">Analyst Score</div>
                </div>

                <div className="col">
                    <div className="percent text-end">
                        {reportScores.investorScore != null ?
                            <>{reportScores.investorScore}<sub>%</sub></> :
                            '-'
                        }
                    </div>
                    <div className="text-end">Investor Score ({reportScores.investorVoteCount})</div>
                </div>
            </div>

            {!isVoteFormShown &&
                <a className="d-block text-mute small text-end" onClick={() => setIsVoteFormShown(!isVoteFormShown)}>Rate this Crypto</a>
            }

            {isVoteFormShown && <VoteForm
                onError={onError}
                onSubmit={onSubmit}
                reportId={reportId}
                setIsVoteFormShown={setIsVoteFormShown}
            />}
        </div>
    );
}

const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

function ReportHeader({ report }) {
    const [isLeftNav, setLeftNav] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('navigation-open', isLeftNav);
    });

    const handleLeftNavToggle = () => {
        setLeftNav(!isLeftNav);
    };

    let authorList = report.authors.filter(author => author.type === AUTHOR_TYPES.AUTHOR);
    let contributorList = report.authors.filter(author => author.type === AUTHOR_TYPES.CONTRIBUTOR);

    const [updateDate, _] = report.updated.split(" ");
    const [year, month, day] = updateDate.split("-");
    const dateObject = new Date(year, month - 1, day);

    return (
        <div className="page-header">
            <div className="row">
                <div className="col-lg-6">
                    <a className="navigation-toggle" onClick={() => handleLeftNavToggle()}><i className="glyph-menu"></i></a>

                    <h1>{report.name} {report.badge && <span className="badge bg-secondary">{report.badge}</span>}</h1>

                    <div className="date">{dateObject.toLocaleDateString("en", dateOptions)}</div>

                </div>
                <div className="col-lg-auto ms-lg-auto">
                    <div className="authors">
                        {authorList.length > 0 &&
                            authorList.map(author => <AuthorCard key={author.id} author={author} />)
                        }

                        {contributorList.length > 0 &&
                            contributorList.map(contributor => <AuthorCard key={contributor.id} author={contributor} />)
                        }
                    </div>
                </div>

            </div>
        </div>
    );

}

function AuthorCard({ author }) {
    return (
        <div className="author">
            <img className="rounded-circle" width="20px" height="20px" src={getProfilePhoto(author)} />
            <span>{`${author.firstName} ${author.lastName}`}</span>
        </div>
    );
}

function HtmlContentCard({ content }) {
    return (
        <div className="card card-report-page mb-5">
            <div className="card-body">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
}

function Report() {
    const [account] = useOutletContext();
    const params = useParams();
    const { data: report, loading, error } = ReportData.useReadReport(account.id, params.reportId);

    const hasElevatedAccount = account.plan != 'free';
    const isDisabledReport = !hasElevatedAccount && !report?.isFree;

    return (
        <div className="main-content">

            {loading && (
                <div className="container-fluid">A moment please...</div>
            )}

            {error && (
                <div className="container-fluid">
                    There is a problem fetching the data
                </div>
            )}

            {report &&

                <div className="report-page">
                    <div className="hero">

                        <div className="desktop-background" style={{ backgroundImage: `url(${report.thumbnail})` }}></div>

                        <div className="container-fluid h-100">
                            <div className="row h-100">
                                <div className="col-5 col-xl-6 align-self-end">
                                    <div className="card-symbol"><img className="icon" src={report.icon} /></div>
                                </div>
                                <div className="col-6 col-xl-4 align-self-center">
                                    <ReportScore report={report}></ReportScore>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-lg-11 col-xl-10 ">
                                <ReportHeader report={report}></ReportHeader>

                                <ReportScore report={report}></ReportScore>

                                {isDisabledReport && <h3>This report is not available with free account.</h3>}

                                {report.summary && <HtmlContentCard content={report.summary}></HtmlContentCard>}

                                {report.content &&
                                    <>
                                        <h5 className="card-label">Full Report</h5>
                                        <HtmlContentCard content={report.content}></HtmlContentCard>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}



export default Report;
