

// Adjustable variables
let settings = {
	pointDensity: 8,
	connections: 2,
	sizeVariation: 0.3,
	velocity: 0.3,
	maxMovement: 50,
	attractionRange: 400,
	attractionFactor: 0.06,
	imagePath: 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiIGZpbGw9IiMwMzEzMjUiLz4KPC9zdmc+',
	imgWidth: 10,
	imgHeight: 10,
	lineColor: "rgba(255,255,255,0.1)",
	particleDensity: 0.2,
	particleChance: 0.2,
	particleVelocity: 70,
	particleColor: "rgba(255,255,255,0.5)",
	particleLength: 8,
	flashRadius: 6,
	flashOpacity: 0.7,
	flashDecay: 0.2
}

let delta = 0;
let lasttimestamp = null;

let points = [];
let	particles = [];

let img = new Image();
img.src = settings.imagePath;
	

function animate(timestamp, context) {
	timestamp = timestamp ? timestamp : 0;
	lasttimestamp = lasttimestamp ? lasttimestamp : timestamp;
	
	delta = (timestamp - lasttimestamp)/100;
	lasttimestamp = timestamp;

	// Move particles
	for (let i = 0; i < particles.length; i++) {
		let particle = particles[i];

		let origin = points[particle.origin];
		let target = origin.closest[particle.target];

		let distance = getDistance({x: origin.x, y: origin.y}, {x: target.x, y: target.y});
		let direction = {x: (target.x - origin.x) / distance, y: (target.y - origin.y) / distance};

		particle.traveled += settings.particleVelocity * delta;
		particle.direction = direction;

		particle.x = origin.x + direction.x * particle.traveled;
		particle.y = origin.y + direction.y * particle.traveled;

		if (!between(origin, {x: particle.x}, target)) {
			particles.splice(i, 1);
			i--;
		}
	}
	
	// Spawn new particles
	for (let i = 0; i < settings.particleDensity * points.length; i++) {
		if (Math.random() < settings.particleChance * delta) {
			let pOriginNum = Math.floor(Math.random()*points.length);
			let pOrigin = points[pOriginNum];
			let pTargetNum = Math.floor(Math.random()*pOrigin.closest.length);
			let px = pOrigin.x;
			let py = pOrigin.y;
			let p = {origin: pOriginNum, target: pTargetNum, x: px, y: py, traveled: 0, direction: {x: 0, y: 0}};
			particles.push(p);
			//pOrigin.flashOpacity = settings.flashOpacity;
		}
	}

	drawFrame(context);
}

function createPoints(context) {
	points = [];
	particles = [];
	for(let x = 0 - 100; x < context.canvas.width + 100; x = x + 1000/settings.pointDensity) {
		for(let y = 0 - 100; y < context.canvas.height + 100; y = y + 1000/settings.pointDensity) {
			let px = Math.floor(x + Math.random()*1000/settings.pointDensity);
			let py = Math.floor(y + Math.random()*1000/settings.pointDensity);
			let pSizeMod = Math.random()*settings.sizeVariation+1
			let pw = settings.imgWidth*pSizeMod;
			let ph = settings.imgHeight*pSizeMod;
			let pAnimOffset = Math.random()*2*Math.PI;
			let p = {x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, animOffset: pAnimOffset, attraction: 0, flashOpacity: 1};
			
			points.push(p);
		}
	}

	// for each point find the closest points. From https://tympanus.net/codrops/2014/09/23/animated-background-headers/
	for(let i = 0; i < points.length; i++) {
		let closest = [];
		let p1 = points[i];
		for(let j = 0; j < points.length; j++) {
			let p2 = points[j]
			if(!contains(p2.closest, p1) && p1 != p2) {
				let placed = false;
				for(let k = 0; k < settings.connections; k++) {
					if(!placed && closest[k] == undefined) {
						closest[k] = p2;
						placed = true;
					}
				}

				for(let k = 0; k < settings.connections; k++) {
					if(!placed && getDistance(p1, p2) < getDistance(p1, closest[k])) {
						closest[k] = p2;
						placed = true;
					}
				}
			}
		}
		p1.closest = closest;
	}

}

function drawFrame(context) {
	context.clearRect(0,0,context.canvas.width,context.canvas.height);

	for (let i = 0; i < points.length; i++) {
		drawLines(context, points[i]);
	}

	for (let i = 0; i < particles.length; i++) {
		let particle = particles[i];
		context.moveTo(particle.x, particle.y);
		context.lineTo(particle.x - particle.direction.x * settings.particleLength, particle.y - particle.direction.y * settings.particleLength);
		context.strokeStyle = settings.particleColor;
		context.stroke();
	}

	for (let i = 0; i < points.length; i++) {
		let point = points [i];
		
		if (point.flashOpacity > 0) {
			context.beginPath();
			context.rect(point.x - settings.flashRadius, point.y - settings.flashRadius, settings.flashRadius * 2, settings.flashRadius * 2);
			
			let gradient = context.createRadialGradient(point.x, point.y, settings.flashRadius, point.x, point.y, 1);
			gradient.addColorStop(0, "rgba(255, 255, 255, 0)");
			gradient.addColorStop(1, "rgba(255, 255, 255, " + point.flashOpacity + ")");

			context.fillStyle = gradient;
			context.fill();
		}
		
		context.drawImage(img, point.x-point.w/2, point.y-point.h/2, point.w, point.h);
	}
	
}

function drawLines(ctx, p) {
	for(let i in p.closest) {
		ctx.beginPath();
		ctx.moveTo(p.x, p.y);
		ctx.lineTo(p.closest[i].x, p.closest[i].y);
		ctx.strokeStyle = settings.lineColor;
		ctx.stroke();
	}
}

//Utils
function getDistance(p1, p2) {
	return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

function contains(a, obj) {
	if (a !== undefined) {
		for (let i = 0; i < a.length; i++) {
			if (a[i] === obj) {
				return true;
			}
		}
	}
	return false;
}

function between(p1, p2, t) {
	return (p1.x - p2.x) * (p2.x - t.x) > 0;
}

export default {
	animate,
	drawFrame,
	createPoints
};