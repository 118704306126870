import React from 'react';
import { Outlet } from 'react-router-dom';
import { ProfileNavigation } from '../components';

const ProfileLayout = () => {

  return (
    <>
      <ProfileNavigation />
      <Outlet />
    </>
  );
}

export default ProfileLayout;
