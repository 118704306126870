import { useState, useEffect } from "react";
import config from 'config';
import { authHeader, handleResponse } from '../helpers';

function useUserProfile() {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return { data, error, loading };
}

function updateGeneral(data) {

    let formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/profile/update/general`, requestOptions)
        .then(response => handleResponse(response));
}

function updatePhoto(data) {
    if (!data.photo.item(0)) {
        return Promise.reject(new Error('no logo'));
    }

    let formData = new FormData();
    formData.append('photo', data.photo.item(0));

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/profile/update/photo`, requestOptions)
        .then(response => handleResponse(response));
}

function removePhoto() {

    let formData = new FormData();

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/profile/remove/photo`, requestOptions)
        .then(response => handleResponse(response));
}

function updatePassword(data) {

    let formData = new FormData();
    formData.append('newPassword1', data.newPassword1);
    formData.append('newPassword2', data.newPassword2);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };

    return fetch(`${config.apiDomain}/api/profile/update/password`, requestOptions)
        .then(response => handleResponse(response));
}

function useUserCourses() {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile/courses`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return { data, error, loading };
}

function useUserRecentCourses() {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile/courses/recent`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return { data, error, loading };
}


function useUserBalances() {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile/balances`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return { data, error, loading };
}

function useUserTokens() {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile/tokens`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return { data, error, loading };
}

function createUserToken(data) {
    const headers = authHeader();
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/profile/tokens`, requestOptions)
        .then(response => handleResponse(response));
}

function deleteUserToken(id) {
    const requestOptions = {
        method: "DELETE",
        cache: "no-cache",
        headers: authHeader(),
    };

    return fetch(`${config.apiDomain}/api/profile/tokens/${id}`, requestOptions)
        .then(response => handleResponse(response));
}

function useUserWallets() {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile/wallets`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(url, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return { data, error, loading };
}

function createUserWallet(data) {
    const headers = authHeader();
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/profile/wallets`, requestOptions)
        .then(response => handleResponse(response));
}

function createUserVerifiedWallet(data) {
    const headers = authHeader();
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/profile/wallets/verified`, requestOptions)
        .then(response => handleResponse(response, true));
}

function updateUserWallet(id, data) {
    const headers = authHeader();
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/profile/wallets/${id}`, requestOptions)
        .then(response => handleResponse(response));
}

function deleteUserWallet(id) {
    const requestOptions = {
        method: "DELETE",
        cache: "no-cache",
        headers: authHeader(),
    };

    return fetch(`${config.apiDomain}/api/profile/wallets/${id}`, requestOptions)
        .then(response => handleResponse(response));
}

function enrolUserInCourse(accountId, data) {
    const headers = authHeader(accountId);
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/profile/courses`, requestOptions)
        .then(response => handleResponse(response, true));
}

function updateUserCourse(accountId, courseId, data) {
    const headers = authHeader(accountId);
    headers["Content-Type"] = "application/json";

    const requestOptions = {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiDomain}/api/profile/courses/${courseId}`, requestOptions)
        .then(response => handleResponse(response, true));
}

function useUserReportScore(reportId) {
    const requestOptions = { method: "GET", headers: authHeader() };
    const url = `${config.apiDomain}/api/profile/report-score`;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`${url}?reportId=${reportId}`, requestOptions)
            .then(response => handleResponse(response, true))
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [reportId]);

    return { data, error, loading };
}

export default {
    useUserProfile,
    updateGeneral,
    updatePhoto,
    removePhoto,
    updatePassword,
    enrolUserInCourse,
    updateUserCourse,
    useUserCourses,
    useUserReportScore,
    useUserTokens,
    createUserToken,
    deleteUserToken,
    useUserRecentCourses,
    useUserBalances,
    createUserWallet,
    createUserVerifiedWallet,
    updateUserWallet,
    deleteUserWallet,
    useUserWallets
};