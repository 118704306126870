
export const SORTS = [
  {
    label: 'Date',
    value: 'updated'
  },
  {
    label: 'Name',
    value: 'name'
  },
];
