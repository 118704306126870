import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import YouTube from 'react-youtube';
import ChapterComplete from "./ChapterComplete";

function YouTubeChapter({ chapter, proceedToNextChapter }) {
    const [isChapterComplete, setIsChapterComplete] = useState(false);

    useEffect(() => {
        // Reset state in case next chapter is also video
        setIsChapterComplete(false);
    }, [chapter])

    const opts = {
        height: '315',
        width: '560',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            rel: 0,
        },
    };

    if (isChapterComplete) {
        return <ChapterComplete proceedToNextChapter={proceedToNextChapter} />;
    }

    return (
        <section>
            {chapter && chapter.name &&
                <h4>{chapter.name}</h4>
            }
            {chapter &&
                <>
                    <YouTube
                        className="ratio ratio-16x9"
                        videoId={chapter.data?.embedId}
                        opts={opts}
                        onEnd={() => setIsChapterComplete(true)}
                    />
                    <div className="mt-3">
                        <ReactMarkdown>{chapter?.description}</ReactMarkdown>
                    </div>
                </>
            }
        </section>
    );

}

export default YouTubeChapter;
