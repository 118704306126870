import AuthService from '../services/AuthService';
import { toast } from 'react-toastify';

function handleResponse(response, suppressToast) {
    suppressToast = suppressToast !== undefined ? suppressToast : false;
    
    if (!response.ok) {
        //console.log(response);
        if ([401, 403].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            AuthService.logout();
        }

        let message = response.statusText;

        // replace with our custom message
       return response.clone().text().then(text => {
            const data = text && JSON.parse(text);
            if (data.status && data.status.message) {
                message = data.status.message;
            }

            // trial has ended
            if (data.status.code == 10001) {
                window.location = '/register/billing'
            }

            toast.error(message);
    
            return Promise.reject(data.status);
        });

    }
    
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if (data && data.status.error) {

            toast.error(data.status.message);

            return Promise.reject(data.status);
        }

        if (!suppressToast)
            toast.success(data.status.message);

        return data.payload;
    });

}

export default handleResponse;