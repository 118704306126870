import React from "react";
import { useForm } from "react-hook-form";

function FileUpload(props) {
    const { onSubmit, onError } = props;

    const { formState: { errors }, handleSubmit, register } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="mb-3">
                <label htmlFor="formFile" className="form-label">Upload file</label>
                <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    {...register("file")}
                />
            </div>
            <div className="mb-3">
                <button type="submit" className="btn btn-primary">
                    UPLOAD
                </button>
            </div>
        </form>
    );
}

export default FileUpload;