import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import ChapterComplete from "./ChapterComplete";

function DownloadsChapter({ chapter, proceedToNextChapter }) {
    return (
        <section>
            {chapter && chapter.name &&
                <h4 className="mb-3">{chapter.name}</h4>
            }
            {chapter &&
                <>
                    <div className="row">
                        {chapter.data.map((file, index) =>
                            <div className="col-md-3 text-center mb-3" key={index}>
                                <a href={file.url} target="_blank">
                                    <div>
                                        <img className="mb-2" srcSet="https://thonic.nyc3.digitaloceanspaces.com/img/file-types/pdf.png 2x" />
                                    </div>
                                    <strong>{file.name}</strong>
                                </a>
                            </div>
                        )}
                    </div>

                    <div className="mt-3">
                        <ReactMarkdown>{chapter?.description}</ReactMarkdown>
                    </div>
                </>
            }
            <button className='btn btn-primary' onClick={proceedToNextChapter}>Next</button>
        </section>
    );

}

export default DownloadsChapter;