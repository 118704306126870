import React from 'react';
import cn from "classnames";

const ChapterCard = ({ chapter, index, current = false, completed = false }) => {
    const chapterCardClassNames = cn({
        'card': true,
        'card-chapter': true,
        'chapter-completed': completed,
        'chapter-current': current,
    });

    return (
        <div className={chapterCardClassNames}>
            <div className="card-body">
                <div className="row gx-2">
                    <div className="col-auto">
                        {completed ? <div className="card-number glyph-check"></div> : <div className="card-number">{index + 1}</div>}
                    </div>
                    <div className="col">
                        <div>{chapter.name}</div>
                        <small className="text-muted">
                            <i className="glyph-timer"></i> {chapter.duration}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChapterCard;
